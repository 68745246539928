import React, { useContext, useState, memo, useMemo, useEffect } from 'react'
import { Button, ButtonGroup, Table } from '@veneer/core'
import { ColumnIndexTypes } from '@veneer/core/dist/scripts/table'
import { FlexRow, FlexColumn, TextBlack, PreviewLabel } from 'styles/styles'
import RestrictColorModal from './RestrictColorModal'
import 'styles/global.scss'
import configContext from 'context/config/configContext'
import { getRowSelectAllState } from 'common/utilities'
import PoliciesHelper from 'context/policies/PoliciesHelper'
import { ArrayAttributeDescription } from 'context/policies/PoliciesConfiguration'
import settingsContext from 'context/settings/settingsContext'
import DropboxItems from 'context/policies/dropboxItems/DropboxItems'
import PreviewItem from 'components/policies/settings/attributes/device/previewItem'
import Retrievei18nItems from 'common/utilityItems/Retrievei18nItems'

const columnId = 'id'
const columnName = 'application_name'
const columnPermission = 'permission'

const RestrictColorControl = (props) => {
  const description: ArrayAttributeDescription = props.description
  const {
    compliance,
    attributes,
    data: { deviceSettings },
    onAttributeChange
  } = props
  const { attribute } = description

  const { tt } = useContext(configContext)
  const { isEnabled, addDisabled, removeDisabled } = useContext(settingsContext)

  const getLocalized = (key: string, params?): string =>
    tt(props.localizationPath, key, params)

  const [existingApplicationNames, setExistingApplicationNames] = useState([])

  const getTablePermissionLocalized = (permission: string) => {
    const found = DropboxItems.restrictDefaultAppParams.find(
      ({ value }) => value === permission
    )
    return found ? getLocalized(found.label) : ''
  }

  const [tableData, setTableData] = useState([])
  const [rowSelectAllState, setRowSelectAllState] = useState(undefined)
  const [selectedCount, setSelectedCount] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [value, setValue] = useState(
    PoliciesHelper.getData(description, deviceSettings)
  )
  const [newTableItems, setNewTableItems] = useState([])
  const onAdd = (v) => {
    setNewTableItems([...newTableItems, tableItem(v)])
    setData([...tableData, tableItem(v)])
  }

  useEffect(() => {
    setValue(PoliciesHelper.getData(description, deviceSettings))
  }, [deviceSettings])

  useEffect(() => {
    const selected = tableData.filter((x) => x.rowConfig.selected).length
    setSelectedCount(selected)
    setRowSelectAllState(getRowSelectAllState(selected, tableData.length))
    setExistingApplicationNames(tableData.map((item) => item.id))
  }, [tableData])

  useEffect(() => {
    // make sure that attribute value is up-to-date, otherwise just wait for sync
    const colorApps = PoliciesHelper.getData(description, deviceSettings)
    if (attributes && JSON.stringify(colorApps) === JSON.stringify(value)) {
      PoliciesHelper.update(
        description,
        value,
        (ids, value) => (value ? removeDisabled(ids) : addDisabled(ids)),
        props.onSettingsChanges,
        attributes
      )
    } else {
      setNewTableItems([])
    }
  }, [value, attributes])

  useEffect(() => {
    setTableData(value ? value.map((x) => tableItem(x)) : [])
  }, [value])

  const enabled = isEnabled(attribute)

  const setData = (newTableData) => {
    setTableData(newTableData)
    const settings = [...deviceSettings]
    const newValue = newTableData.map((x) => x.rowConfig.item)
    PoliciesHelper.setData(description, settings, newValue)
    onAttributeChange({ ...props.data, deviceSettings: settings })
  }

  const onRemove = () => {
    const data = tableData.filter((item) => !item.rowConfig.selected)
    const removedItems = tableData
      .filter((item) => item.rowConfig.selected)
      .map((item) => item.id)

    const remainingNewItems = newTableItems.filter(
      (item) => !removedItems.includes(item.id)
    )
    setNewTableItems(remainingNewItems)
    setData(data)
  }

  const tableItem = (item) => {
    return {
      id: item.name,
      name: item.name,
      permission: getTablePermissionLocalized(`${item.permission}`),
      rowConfig: { item }
    }
  }

  const tableColumns = useMemo(() => {
    const index: ColumnIndexTypes = 'hidden'
    return [
      { id: columnId, label: columnId, index },
      {
        id: 'name',
        label: getLocalized(columnName)
      },
      {
        id: columnPermission,
        label: getLocalized(columnPermission)
      }
    ]
  }, [])

  const handleRowSelect = (event, rowId) => {
    const { checked } = event.target
    const found = tableData.find(({ id }) => id === rowId)
    if (found) {
      found.rowConfig.selected = checked
      setTableData([...tableData])
    }
  }

  const handleSelectAllPageItems = (event) => {
    const { checked } = event.target
    const newTableData = tableData.map((row) => {
      const rowCopy = row
      row.rowConfig.selected = checked
      return rowCopy
    })
    setTableData(newTableData)
  }

  const tableHeader = (text) =>
    !onAttributeChange ? (
      <PreviewLabel>{text}</PreviewLabel>
    ) : (
      <TextBlack className={'marginBottom12'} disabled={!enabled}>
        {text}
      </TextBlack>
    )

  return (
    <>
      <FlexColumn>
        {tableHeader(getLocalized('app-perm'))}
        {onAttributeChange && (
          <FlexRow className={'marginBottom12 alignCenter'}>
            <ButtonGroup>
              <Button
                appearance={'secondary'}
                disabled={!enabled}
                onClick={() => setShowModal(true)}
                id={'addResistColorPermission'}
              >
                {getLocalized('common.add')}
              </Button>
              <Button
                appearance={'secondary'}
                disabled={!enabled || selectedCount < 1}
                onClick={onRemove}
                id={'removeRestrictColor'}
              >
                {getLocalized('common.remove')}
              </Button>
            </ButtonGroup>
            <TextBlack className={'marginLeft12'} disabled={!enabled}>
              {getLocalized('n-application-added', {
                count: newTableItems.length
              })}
            </TextBlack>
          </FlexRow>
        )}
        <Table
          columns={tableColumns}
          data={tableData}
          onSelect={handleRowSelect}
          onSelectAllPageItems={handleSelectAllPageItems}
          rowSelector={
            onAttributeChange && enabled ? 'multiSelection' : undefined
          }
          rowSelectAllState={rowSelectAllState}
          className={'widthColAuto'}
          data-testid={'id-restrict-color-table'}
          preferences={{
            width: [
              { columnId: 'name', width: 150 },
              { columnId: columnPermission, width: 150 }
            ]
          }}
          i18n={Retrievei18nItems()}
        />
        {compliance && (
          <PreviewItem compliance={compliance} className={'marginTop4'} />
        )}
      </FlexColumn>
      {showModal && (
        <RestrictColorModal
          getLocalized={getLocalized}
          onAdd={onAdd}
          onClose={() => setShowModal(false)}
          existingApplicationNames={existingApplicationNames}
        />
      )}
    </>
  )
}

export default memo(RestrictColorControl)
