import { Attribute, DeviceSettings } from 'common/model/api/Policy'
import PoliciesCategories, {
  PolicyAttributeEnum,
  PolicyCategoryEnum
} from 'context/policies/PoliciesCategories'
import PoliciesErrors, {
  Condition,
  MessageTypesEnum
} from 'context/policies/PoliciesErrors'
import PolicyConstraints, {
  ConstraintsResourceEnum,
  ConstraintsSettingEnum
} from 'context/constraints/Constraints'
import DropboxItems from 'context/policies/dropboxItems/DropboxItems'
import LanguageItems from 'context/policies/dropboxItems/LanguageItems'
import CountryItems from 'context/policies/dropboxItems/CountryItems'
import PrintMediaItems from 'context/policies/dropboxItems/PrintMediaItems'
import DuplexBindingItems from 'context/policies/dropboxItems/DuplexBindingItems'
import DefPrintOptions from 'context/policies/dropboxItems/DefPrintOptions'
import WebEncryptionItems from 'context/policies/dropboxItems/WebEncryptionItems'
import LinkSettingItems from 'context/policies/dropboxItems/LinkSettingItems'
import MediaTrayItems from 'context/policies/dropboxItems/MediaTrayItems'
import ConfigurationPrecedence from 'context/policies/dropboxItems/ConfigurationPrecedence'
import HomeScreenLanguageItems from 'context/policies/dropboxItems/HomeScreenLanguageItems'
import ImageSettingItems from 'context/policies/dropboxItems/ImageSettingItems'
import { abcSort, isTrueString } from 'common/utilities'
import PrintOptimizeImageItems from './dropboxItems/PrintOptimizeImageItems'
import PrintEmailNotificationItems from './dropboxItems/PrintEmailNotificationItems'
import BytesOutputItems from './dropboxItems/BytesOutputItems'
import MinuteItems from './dropboxItems/MinuteItems'
import EmailAddressItems from './dropboxItems/EmailAddressItems'
import FaxReceiveItems from './dropboxItems/FaxReceiveItems'
import FileSettingItems from './dropboxItems/FileSettingItems'
import TimeZoneItems, { dstEnum } from './dropboxItems/TimeZoneItems'
import ColorItems from './dropboxItems/ColorItems'
import WiFiDirectItems from './dropboxItems/WiFiDirectItems'
import SupplyActionItems from './dropboxItems/SupplyActionItems'
import CopyColorItems from './dropboxItems/CopyColorItems'
import WakeOnItems from './dropboxItems/WakeOnItems'
import DeviceAccessItems from './dropboxItems/DeviceAccessItems'

export enum PolicyItemPrimitives {
  Password = 'password', // not used
  PasswordView = 'password-view',
  Textbox = 'textbox',
  Toggle = 'toggle',
  ToggleView = 'toggle-view',
  Dropbox = 'dropbox',
  PriorityList = 'priority-list',
  MultiSelectCheckbox = 'multi-select-checkbox',
  DelayLowMsgThreshold = 'delay-low-msg-threshold',
  FwUpdateSchedule = 'fwu-schedule',
  FwUpdateVersion = 'fwu-version',
  TrayAdministration = 'tray-administration',
  HomeScreenLanguageAndKeyboard = 'home-screen-language-and-keyboard',
  HomeScreenApplications = 'home-screen-applications',
  OutgoingServers = 'outgoing-servers',
  RestrictColor = 'restrict-color',
  CopyStamps = 'copy-stamps',
  SimpleTable = 'simple-table',
  EmailMessage = 'email-message',
  FaxReceive = 'fax-receive',
  FaxSend = 'fax-send',
  FileName = 'file-name',
  RadioTextBox = 'radio-text-box',
  ObjectDropBox = 'object-dropbox',
  AppDeployment = 'app-deployment',
  SioConfig = 'sio-config',
  FileUpload = 'file-upload',
  WallpaperUpload = 'wallpaper-upload',
  WeeklySchedule = 'weekly-schedule',
  HolidaySchedule = 'holiday-schedule',
  QuickSets = 'quick-sets',
  AccessCtrlRoles = 'access-ctrl-roles',
  AccessCtrlPermissions = 'access-ctrl-permissions',
  WebEncryption = 'web-encryption',
  Error = 'error' // non-data type: displays error if enabled
}

export enum ControlWidthEnum {
  NORMAL = 'maxTextWidth', //default
  REDUCED = 'midTextWidth'
}

export type enableCallBackType = (keys: string[], enabled: boolean) => void
export type setValueCallBackType = (deviceValues: DeviceSettings[]) => void

export interface Item {
  readonly value: string
  readonly label?: string
  readonly option?: string
}

export interface DeviceSettingDescription {
  readonly type:
    | PolicyItemPrimitives.Toggle
    | PolicyItemPrimitives.ToggleView
    | PolicyItemPrimitives.Textbox
    | PolicyItemPrimitives.Password
    | PolicyItemPrimitives.PasswordView
    | PolicyItemPrimitives.Dropbox
    | PolicyItemPrimitives.MultiSelectCheckbox
    | PolicyItemPrimitives.DelayLowMsgThreshold
    | PolicyItemPrimitives.PriorityList
    | PolicyItemPrimitives.FwUpdateVersion
    | PolicyItemPrimitives.FwUpdateSchedule
    | PolicyItemPrimitives.TrayAdministration
    | PolicyItemPrimitives.HomeScreenLanguageAndKeyboard
    | PolicyItemPrimitives.HomeScreenApplications
    | PolicyItemPrimitives.OutgoingServers
    | PolicyItemPrimitives.RestrictColor
    | PolicyItemPrimitives.CopyStamps
    | PolicyItemPrimitives.SimpleTable
    | PolicyItemPrimitives.EmailMessage
    | PolicyItemPrimitives.FaxReceive
    | PolicyItemPrimitives.FaxSend
    | PolicyItemPrimitives.FileName
    | PolicyItemPrimitives.RadioTextBox
    | PolicyItemPrimitives.ObjectDropBox
    | PolicyItemPrimitives.AppDeployment
    | PolicyItemPrimitives.SioConfig
    | PolicyItemPrimitives.FileUpload
    | PolicyItemPrimitives.WallpaperUpload
    | PolicyItemPrimitives.WeeklySchedule
    | PolicyItemPrimitives.HolidaySchedule
    | PolicyItemPrimitives.QuickSets
    | PolicyItemPrimitives.AccessCtrlRoles
    | PolicyItemPrimitives.AccessCtrlPermissions
    | PolicyItemPrimitives.WebEncryption
    | PolicyItemPrimitives.Error
  readonly attribute: string
  readonly indent?: number
}

export interface ErrorDescription extends DeviceSettingDescription {
  readonly type: PolicyItemPrimitives.Error
  readonly error: string
  readonly params?: Record<string, unknown>
}

export interface DeviceSettingHelpDescription extends DeviceSettingDescription {
  readonly type:
    | PolicyItemPrimitives.AppDeployment
    | PolicyItemPrimitives.CopyStamps
    | PolicyItemPrimitives.DelayLowMsgThreshold
    | PolicyItemPrimitives.Dropbox
    | PolicyItemPrimitives.EmailMessage
    | PolicyItemPrimitives.FaxReceive
    | PolicyItemPrimitives.FaxSend
    | PolicyItemPrimitives.FileName
    | PolicyItemPrimitives.FileUpload
    | PolicyItemPrimitives.FwUpdateVersion
    | PolicyItemPrimitives.FwUpdateSchedule
    | PolicyItemPrimitives.HolidaySchedule
    | PolicyItemPrimitives.HomeScreenApplications
    | PolicyItemPrimitives.HomeScreenLanguageAndKeyboard
    | PolicyItemPrimitives.MultiSelectCheckbox
    | PolicyItemPrimitives.ObjectDropBox
    | PolicyItemPrimitives.OutgoingServers
    | PolicyItemPrimitives.Password
    | PolicyItemPrimitives.PasswordView
    | PolicyItemPrimitives.PriorityList
    | PolicyItemPrimitives.QuickSets
    | PolicyItemPrimitives.AccessCtrlRoles
    | PolicyItemPrimitives.AccessCtrlPermissions
    | PolicyItemPrimitives.RadioTextBox
    | PolicyItemPrimitives.RestrictColor
    | PolicyItemPrimitives.SimpleTable
    | PolicyItemPrimitives.SioConfig
    | PolicyItemPrimitives.Textbox
    | PolicyItemPrimitives.Toggle
    | PolicyItemPrimitives.ToggleView
    | PolicyItemPrimitives.TrayAdministration
    | PolicyItemPrimitives.WallpaperUpload
    | PolicyItemPrimitives.WeeklySchedule
    | PolicyItemPrimitives.WebEncryption
  readonly help?: string
  readonly disabledHelp?: string
}

export interface BooleanSettingDescription {
  update?: (
    value: boolean,
    enableCallBack: enableCallBackType,
    setValueCallBack: setValueCallBackType,
    attributes: Attribute[]
  ) => void
}

export interface StringSettingDescription {
  update?: (
    value: string,
    enableCallBack: enableCallBackType,
    setValueCallBack: setValueCallBackType,
    attributes: Attribute[]
  ) => void
}

export interface ArraySettingDescription {
  update?: (
    value: [],
    enableCallBack: enableCallBackType,
    setValueCallBack: setValueCallBackType,
    attributes: Attribute[]
  ) => void
}

export interface ObjectSettingDescription {
  update?: (
    value: Record<string, string>,
    enableCallBack: enableCallBackType,
    setValueCallBack: setValueCallBackType,
    attributes: Attribute[]
  ) => void
}

export interface LabelSettingDescription {
  readonly label: string
}

export interface LabelOptSettingDescription {
  readonly label?: string
}

export interface HintSettingDescription extends LabelOptSettingDescription {
  readonly helper?: string
  readonly hint: string
}

export interface ErrorsSettingDescription {
  readonly errors?: Condition[]
}

export interface ItemsSettingDescription {
  readonly items: Item[]
}

export interface WidthStyleDescription {
  readonly widthStyle?: ControlWidthEnum
}

export interface ConstraintsDescription {
  readonly constraints?: PolicyConstraints
}

export interface ToggleDescription
  extends DeviceSettingHelpDescription,
    BooleanSettingDescription,
    LabelSettingDescription {
  readonly type: PolicyItemPrimitives.Toggle | PolicyItemPrimitives.ToggleView
  readonly warning?: Condition
}

export interface TextBoxDescription
  extends DeviceSettingHelpDescription,
    StringSettingDescription,
    HintSettingDescription,
    ErrorsSettingDescription,
    WidthStyleDescription {
  readonly type: PolicyItemPrimitives.Textbox
  readonly textArea?: boolean
}

export interface PasswordDescription
  extends DeviceSettingHelpDescription,
    StringSettingDescription,
    HintSettingDescription,
    ErrorsSettingDescription {
  readonly type:
    | PolicyItemPrimitives.Password
    | PolicyItemPrimitives.PasswordView
  readonly confirmationHint?: string
  readonly errorMessage?: string
}

export interface DropBoxDescription
  extends DeviceSettingHelpDescription,
    StringSettingDescription,
    LabelOptSettingDescription,
    ItemsSettingDescription {
  readonly type: PolicyItemPrimitives.Dropbox
  readonly warnings?: Condition[]
  readonly radio?: boolean
}

export interface ObjectDropboxDescription
  extends DeviceSettingHelpDescription,
    ObjectSettingDescription,
    LabelSettingDescription,
    ItemsSettingDescription {
  readonly type: PolicyItemPrimitives.ObjectDropBox
}

export interface MultiSelectCheckboxDescription
  extends DeviceSettingHelpDescription,
    ArraySettingDescription,
    ItemsSettingDescription {
  readonly type: PolicyItemPrimitives.MultiSelectCheckbox
  readonly label?: string
  readonly select: string
  readonly change: string
  readonly description: string
  readonly selected: string
  readonly tableColumn: string
  readonly error: string
}

export interface FwUpdateScheduleDescription
  extends DeviceSettingHelpDescription,
    StringSettingDescription,
    LabelSettingDescription {
  readonly type: PolicyItemPrimitives.FwUpdateSchedule
}

export interface PriorityListControlDescription
  extends DeviceSettingHelpDescription,
    ArraySettingDescription,
    LabelSettingDescription {
  readonly type: PolicyItemPrimitives.PriorityList
  readonly items: PriorityItem[]
}

export interface TrayAdministrationDescription
  extends DeviceSettingHelpDescription,
    ObjectSettingDescription {
  readonly type: PolicyItemPrimitives.TrayAdministration
  readonly trays: Item[]
  readonly sizes: Item[]
  readonly types: Item[]
}

export interface ObjectAttributeDescription
  extends DeviceSettingHelpDescription,
    ObjectSettingDescription,
    ConstraintsDescription {
  readonly type:
    | PolicyItemPrimitives.DelayLowMsgThreshold
    | PolicyItemPrimitives.EmailMessage
    | PolicyItemPrimitives.FaxReceive
    | PolicyItemPrimitives.FaxSend
    | PolicyItemPrimitives.FileName
    | PolicyItemPrimitives.RadioTextBox
    | PolicyItemPrimitives.SioConfig
    | PolicyItemPrimitives.FileUpload
    | PolicyItemPrimitives.WebEncryption
}

export interface ArrayAttributeDescription
  extends DeviceSettingHelpDescription,
    ArraySettingDescription,
    ConstraintsDescription {
  readonly type:
    | PolicyItemPrimitives.OutgoingServers
    | PolicyItemPrimitives.RestrictColor
    | PolicyItemPrimitives.CopyStamps
    | PolicyItemPrimitives.AppDeployment
    | PolicyItemPrimitives.WallpaperUpload
    | PolicyItemPrimitives.WeeklySchedule
    | PolicyItemPrimitives.HolidaySchedule
    | PolicyItemPrimitives.QuickSets
    | PolicyItemPrimitives.AccessCtrlRoles
}

export interface ArrayItemsAttributeDescription
  extends DeviceSettingHelpDescription,
    ArraySettingDescription,
    ItemsSettingDescription {
  readonly type: PolicyItemPrimitives.HomeScreenLanguageAndKeyboard
}

export interface FwUpdateVersionDescription
  extends DeviceSettingHelpDescription,
    ArraySettingDescription {
  readonly model: string
  readonly type: PolicyItemPrimitives.FwUpdateVersion
}

export interface HomeScreenAppsDescription
  extends DeviceSettingHelpDescription,
    ArraySettingDescription {
  readonly simple: boolean
  readonly type: PolicyItemPrimitives.HomeScreenApplications
}

export interface AccessControlPermissionDescription
  extends DeviceSettingHelpDescription,
    ArraySettingDescription,
    LabelSettingDescription,
    ItemsSettingDescription {
  readonly type: PolicyItemPrimitives.AccessCtrlPermissions
}

export interface RadioTextBoxDescription
  extends ObjectAttributeDescription,
    LabelSettingDescription,
    WidthStyleDescription {
  readonly type: PolicyItemPrimitives.RadioTextBox
  readonly radioKey: string
  readonly textKey: string
  readonly items: ItemEx[]
}

export interface ItemEx extends Item, ErrorsSettingDescription {
  readonly hint: string
  readonly helper?: string
}

export interface PriorityItem extends Item {
  readonly fixed?: boolean
}

export interface SimpleTableDescription
  extends DeviceSettingHelpDescription,
    ErrorsSettingDescription,
    LabelSettingDescription,
    ArraySettingDescription {
  readonly type: PolicyItemPrimitives.SimpleTable
  readonly limit?: number
  readonly emptyAllowed?: boolean
  readonly countLabel: string
  readonly tableColumn: string
  readonly modalDescription: string
  readonly modalTextLabel: string
  readonly modalHelperText: string
  readonly duplicateError?: string
}

export interface FileUploadDescription
  extends DeviceSettingHelpDescription,
    ObjectAttributeDescription {
  readonly type: PolicyItemPrimitives.FileUpload
  readonly fileUploadExt?: string
  readonly fileUploadType?: string
}

export interface PolicyAttributeDescription {
  id: PolicyAttributeEnum
  relatedItems?: PolicyAttributeEnum[]
  deviceSettings?: (
    | ToggleDescription
    | TextBoxDescription
    | PasswordDescription
    | DropBoxDescription
    | MultiSelectCheckboxDescription
    | FwUpdateVersionDescription
    | FwUpdateScheduleDescription
    | PriorityListControlDescription
    | TrayAdministrationDescription
    | ArrayItemsAttributeDescription
    | ArrayAttributeDescription
    | ObjectAttributeDescription
    | SimpleTableDescription
    | RadioTextBoxDescription
    | ObjectDropboxDescription
    | FileUploadDescription
    | HomeScreenAppsDescription
    | AccessControlPermissionDescription
    | ErrorDescription
  )[]
}

export interface PolicyAttributeDescriptionEx
  extends PolicyAttributeDescription {
  categories: PolicyCategoryEnum[]
}

const sFalse = 'false'
const sZeroIp4 = '0.0.0.0'
const autoSendChecks = [
  `${PolicyAttributeEnum.Auto_Send}.web-hp`,
  `${PolicyAttributeEnum.Auto_Send}.web`,
  `${PolicyAttributeEnum.Auto_Send}.email`
]
const restrictColorChecks = [
  `${PolicyAttributeEnum.Restrict_Color}.user`,
  `${PolicyAttributeEnum.Restrict_Color}.app`
]
const sleepSettingsValues = [
  `${PolicyAttributeEnum.Sleep_Settings}.value`,
  `${PolicyAttributeEnum.Sleep_Settings}.off-value`
]
const sleepSettingsLimit = 59

export default class PoliciesConfiguration {
  // Helpers
  static errorsAutoSendEvery = [PoliciesErrors.errorWrongCharNumber]

  static outOfRange(value: string, min: number, max: number): boolean {
    if (/^(?!0.)\d+$/.test(value)) {
      const numValue = parseInt(value, 10)
      return numValue > max || numValue < min
    }
    return true
  }

  static getValue = (
    attributes: Attribute[],
    attribute: PolicyAttributeEnum,
    setting: string
  ) => {
    const value = PoliciesConfiguration.getStringValue(
      attributes,
      attribute,
      setting
    )
    return value === null ? null : isTrueString(value)
  }

  static getStringValue = (
    attributes: Attribute[],
    attribute: PolicyAttributeEnum,
    setting: string
  ) => {
    const attr = attributes.find((x) => x.name === attribute)
    if (attr) {
      const set = attr.deviceSettings.find((x) => x.name === setting)
      return set ? set.value : ''
    }
    return null
  }

  static resetChecks = (
    enable: boolean,
    enableCallback: enableCallBackType,
    setValueCallback: setValueCallBackType,
    ids: string[]
  ) => {
    enableCallback(ids, enable)
    if (!enable) {
      setValueCallback(ids.map((name) => ({ name, value: sFalse })))
    }
  }

  static updateDnsServer = (
    value,
    enableCallback: enableCallBackType,
    setValueCallback: setValueCallBackType,
    id: string
  ) => {
    enableCallback([id], !value)
    if (value) {
      setValueCallback([{ name: id, value: sZeroIp4 }])
    }
  }

  static updateAirPrint = (
    value,
    enableCallback: enableCallBackType,
    setValueCallback: setValueCallBackType,
    attributes: Attribute[]
  ) => {
    const airPrintEnabled = (attributes: Attribute[]): boolean => {
      if (
        PoliciesConfiguration.getValue(
          attributes,
          PolicyAttributeEnum.Bonjour,
          `${PolicyAttributeEnum.Bonjour}.check`
        ) === false
      ) {
        return false
      }
      if (
        PoliciesConfiguration.getValue(
          attributes,
          PolicyAttributeEnum.Ipp,
          `${PolicyAttributeEnum.Ipp}.check`
        ) !== false
      ) {
        return true
      }
      return (
        PoliciesConfiguration.getValue(
          attributes,
          PolicyAttributeEnum.Ipps,
          `${PolicyAttributeEnum.Ipps}.check`
        ) !== false
      )
    }
    PoliciesConfiguration.resetChecks(
      airPrintEnabled(attributes),
      enableCallback,
      setValueCallback,
      [`${PolicyAttributeEnum.Airprint}.check`]
    )
  }

  static updateWsPrint = (
    value,
    enableCallback: enableCallBackType,
    setValueCallback: setValueCallBackType,
    attributes: Attribute[]
  ) =>
    PoliciesConfiguration.resetChecks(
      PoliciesConfiguration.getValue(
        attributes,
        PolicyAttributeEnum.Ws_Discovery,
        `${PolicyAttributeEnum.Ws_Discovery}.check`
      ) !== false,
      enableCallback,
      setValueCallback,
      [`${PolicyAttributeEnum.Ws_Print}.check`]
    )

  static updateCaCertificate = (
    value,
    enableCallback: enableCallBackType,
    setValueCallback: setValueCallBackType,
    attributes: Attribute[]
  ) =>
    enableCallback(
      [`${PolicyAttributeEnum.Ca_Certificate}.file`],
      PoliciesConfiguration.getValue(
        attributes,
        PolicyAttributeEnum.Csrf_Prevention,
        `${PolicyAttributeEnum.Csrf_Prevention}.check`
      ) !== true
    )

  static updateAutoSend = (
    value,
    enableCallback: enableCallBackType,
    attributes: Attribute[],
    id
  ) => {
    enableCallback([id], value)
    enableCallback(
      [PolicyAttributeEnum.Auto_Send],
      PoliciesConfiguration.getValue(
        attributes,
        PolicyAttributeEnum.Auto_Send,
        `${PolicyAttributeEnum.Auto_Send}.check`
      ) === true &&
        autoSendChecks.every(
          (x) =>
            PoliciesConfiguration.getValue(
              attributes,
              PolicyAttributeEnum.Auto_Send,
              x
            ) !== true
        )
    )
  }

  static updateRestrictColor = (
    value,
    enableCallback: enableCallBackType,
    attributes: Attribute[],
    ids?
  ) => {
    if (ids) {
      enableCallback(ids, value)
    }
    enableCallback(
      [PolicyAttributeEnum.Restrict_Color],
      PoliciesConfiguration.getStringValue(
        attributes,
        PolicyAttributeEnum.Restrict_Color,
        `${PolicyAttributeEnum.Restrict_Color}.mode`
      ) === `${PolicyAttributeEnum.Restrict_Color}.mode.custom-access` &&
        restrictColorChecks.every(
          (x) =>
            PoliciesConfiguration.getValue(
              attributes,
              PolicyAttributeEnum.Restrict_Color,
              x
            ) !== true
        )
    )
  }

  static updateSleepSettings = (
    value,
    enableCallback: enableCallBackType,
    attributes: Attribute[],
    ids?
  ) => {
    if (ids) {
      enableCallback(ids, value)
    }
    const sum = () =>
      sleepSettingsValues.reduce(
        (acc, x) =>
          acc +
          parseInt(
            PoliciesConfiguration.getStringValue(
              attributes,
              PolicyAttributeEnum.Sleep_Settings,
              x
            ),
            10
          ),
        0
      )
    enableCallback(
      [PolicyAttributeEnum.Sleep_Settings],
      PoliciesConfiguration.getValue(
        attributes,
        PolicyAttributeEnum.Sleep_Settings,
        `${PolicyAttributeEnum.Sleep_Settings}.check`
      ) === true && sum() > sleepSettingsLimit
    )
  }

  // Warnings

  static snmpPermissionWarnings: Condition[] = [
    {
      value: `${PolicyAttributeEnum.SNMP_V1_V2}.permission.read-write`,
      message: 'warning_message'
    }
  ]

  static wakeOnEventsInfoMsgs: Condition[] = [
    {
      value: `${PolicyAttributeEnum.Sleep_Settings}.on-events.power-btn`,
      message: 'info_message',
      messageType: MessageTypesEnum.INFO
    }
  ]

  static fileEraseModeWarnings: Condition[] = [
    {
      value: `${PolicyAttributeEnum.File_Erase_Mode}.mode.non-secure`,
      message: 'warning_message'
    }
  ]

  static ctrlPanelLockOptionWarnings: Condition[] = [
    {
      value: `${PolicyAttributeEnum.Control_Panel_Lock}.option.unlock`,
      message: 'warning_message_unlock'
    }
  ]

  static diskEncryptionStatusWarnings: Condition[] = [
    {
      value: `${PolicyAttributeEnum.Disk_Encryption_Status}.status.inactive`,
      message: 'warning_message'
    }
  ]

  // Simple attribute helpers

  static aText = (
    id,
    errors,
    helper = false,
    help = false,
    reducedWidth = false
  ): PolicyAttributeDescription => {
    const setting: TextBoxDescription = {
      type: PolicyItemPrimitives.Textbox,
      helper: helper ? 'value_helper' : undefined,
      label: 'value',
      help: help ? 'value_help' : undefined,
      hint: 'value_hint',
      errors,
      attribute: `${id}.value`,
      widthStyle: reducedWidth ? ControlWidthEnum.REDUCED : undefined
    }
    return { id, deviceSettings: [setting] }
  }

  static aToggle = (
    id,
    value = undefined,
    relatedItems = undefined,
    update = undefined
  ): PolicyAttributeDescription => {
    const setting: ToggleDescription = {
      type: PolicyItemPrimitives.Toggle,
      label: 'check',
      warning:
        value === undefined ? undefined : { value, message: 'warning_message' },
      attribute: `${id}.check`,
      update
    }
    return { id, relatedItems, deviceSettings: [setting] }
  }

  static aDropdown = (
    id,
    items,
    label = 'value',
    help = false,
    warnings = undefined,
    radio = false
  ): PolicyAttributeDescription => {
    const setting: DropBoxDescription = {
      type: PolicyItemPrimitives.Dropbox,
      label,
      help: help ? `${label}_help` : undefined,
      items,
      warnings,
      radio,
      attribute: `${id}.${label}`
    }
    return { id, deviceSettings: [setting] }
  }

  static aRadio = (id, items, label): PolicyAttributeDescription =>
    PoliciesConfiguration.aDropdown(id, items, label, false, undefined, true)

  // Duplicates

  static aNotification = (id): PolicyAttributeDescription => {
    return {
      id,
      deviceSettings: [
        {
          type: PolicyItemPrimitives.Dropbox,
          label: 'condition',
          items: PrintEmailNotificationItems.emailNotificationConditions,
          attribute: `${id}.condition`,
          radio: true,
          update(value, enableCallBack, _, attributes) {
            const notificationCondition = PoliciesConfiguration.getStringValue(
              attributes,
              id,
              `${id}.method`
            )
            enableCallBack(
              [`${id}.method`],
              value !== 'notification-condition.none'
            )
            if (notificationCondition === 'notification-method.email') {
              enableCallBack(
                [`${id}.address`],
                value !== 'notification-condition.none'
              )
            }
          }
        },
        {
          type: PolicyItemPrimitives.Dropbox,
          items: PrintEmailNotificationItems.emailNotificationMethods,
          label: 'method',
          attribute: `${id}.method`,
          radio: true,
          indent: 1,
          update(value, enableCallBack, _, attributes) {
            const notificationCondition = PoliciesConfiguration.getStringValue(
              attributes,
              id,
              `${id}.condition`
            )
            enableCallBack(
              [`${id}.address`],
              value === 'notification-method.email' &&
                notificationCondition !== 'notification-condition.none'
            )
          }
        },
        {
          type: PolicyItemPrimitives.Textbox,
          hint: 'address_hint',
          errors: PoliciesErrors.errorsInvalidEmail,
          attribute: `${id}.address`,
          indent: 2
        }
      ]
    }
  }

  static aFileSetting = (id, constraints): PolicyAttributeDescription => {
    return {
      id,
      deviceSettings: [
        {
          type: PolicyItemPrimitives.FileName,
          attribute: `${id}.name`
        },
        {
          type: PolicyItemPrimitives.Dropbox,
          label: 'media-size',
          items: PrintMediaItems.mediaSizes(constraints),
          attribute: `${id}.media-size`
        },
        {
          type: PolicyItemPrimitives.Dropbox,
          label: 'color-preference',
          items: FileSettingItems.fileColorPrefItems,
          attribute: `${id}.color-preference`
        },
        {
          type: PolicyItemPrimitives.Dropbox,
          label: 'quality',
          items: FileSettingItems.fileQualityItems,
          attribute: `${id}.quality`
        },
        {
          type: PolicyItemPrimitives.Dropbox,
          label: 'resolution',
          items: FileSettingItems.fileResolutionItems,
          attribute: `${id}.resolution`
        },
        {
          type: PolicyItemPrimitives.Dropbox,
          label: 'type',
          items: FileSettingItems.fileTypeItems,
          attribute: `${id}.type`,
          update: (value, enableCallback, setValueCallBack) => {
            // TIFF
            enableCallback(
              [`${id}.compression-black`, `${id}.compression-color`],
              FileSettingItems.isFileTypeTiff(value)
            )
            // PDF
            enableCallback(
              [`${id}.compression`],
              FileSettingItems.isFileTypeCompressed(value)
            )
            const pdfEncryptionId = `${id}.pdf-encryption`
            const pdf = FileSettingItems.isFileTypePdf(value)
            enableCallback([pdfEncryptionId], pdf)
            if (!pdf) {
              setValueCallBack([{ name: pdfEncryptionId, value: sFalse }])
            }
          }
        },
        {
          type: PolicyItemPrimitives.Dropbox,
          label: 'compression-black',
          items: FileSettingItems.fileCompressionBlackItems,
          attribute: `${id}.compression-black`,
          indent: 1
        },
        {
          type: PolicyItemPrimitives.Dropbox,
          label: 'compression-color',
          items: FileSettingItems.fileCompressionColorItems,
          attribute: `${id}.compression-color`,
          indent: 1
        },
        {
          type: PolicyItemPrimitives.Dropbox,
          label: 'compression',
          items: FileSettingItems.fileCompressionItems,
          attribute: `${id}.compression`,
          indent: 1
        },
        {
          type: PolicyItemPrimitives.Toggle,
          label: 'pdf-encryption',
          attribute: `${id}.pdf-encryption`,
          indent: 1
        },
        {
          type: PolicyItemPrimitives.Toggle,
          label: 'blank-page-suppression',
          attribute: `${id}.blank-page-suppression`
        }
      ]
    }
  }

  // Configuration

  static getConfiguration = (id, constraints): PolicyAttributeDescription => {
    switch (id) {
      // Checkboxes
      case PolicyAttributeEnum.Svc_Location_Protocol:
      case PolicyAttributeEnum.IPv4_Multicast:
      case PolicyAttributeEnum.LLMNR:
      case PolicyAttributeEnum.Lpd_Lpr:
      case PolicyAttributeEnum.PostScript_Security:
      case PolicyAttributeEnum.Verify_Certificate:
      case PolicyAttributeEnum.Enable_WINS_Port:
      case PolicyAttributeEnum.WINS_Registration:
      case PolicyAttributeEnum.Firmware_Downgrade:
      case PolicyAttributeEnum.JetDirect_Xml_Cfg:
      case PolicyAttributeEnum.Supplies_Status_Msgs_On_Ctrl_Panel:
      case PolicyAttributeEnum.Save_to_SharePoint:
      case PolicyAttributeEnum.Supply_Low_Alerts:
      case PolicyAttributeEnum.Save_to_Email:
      case PolicyAttributeEnum.Save_to_Network_Folder:
      case PolicyAttributeEnum.Ftp_Print:
      case PolicyAttributeEnum.Color_Usage_Log:
      case PolicyAttributeEnum.Size_Type_Prompt:
      case PolicyAttributeEnum.Override_Letter_A4:
      case PolicyAttributeEnum.Show_Date_Time:
      case PolicyAttributeEnum.Smart_Cloud_Print:
      case PolicyAttributeEnum.PC_Fax_Send:
      case PolicyAttributeEnum.Print_Anywhere_Securely:
      case PolicyAttributeEnum.Copy_Job_Build:
        return PoliciesConfiguration.aToggle(id)

      case PolicyAttributeEnum.Auto_FW_Update:
      case PolicyAttributeEnum.HTTPS_Redirect:
        return PoliciesConfiguration.aToggle(id, false)

      case PolicyAttributeEnum.Remote_FW_Update:
      case PolicyAttributeEnum.Dc_Ports:
      case PolicyAttributeEnum.Novell_Cfg:
      case PolicyAttributeEnum.Telnet:
      case PolicyAttributeEnum.Tftp_Cfg:
      case PolicyAttributeEnum.Legacy_Firmware_Upgrade:
        return PoliciesConfiguration.aToggle(id, true)

      case PolicyAttributeEnum.PJL_Access_Commands:
        return PoliciesConfiguration.aToggle(id, undefined, [
          PolicyAttributeEnum.PJL_Password
        ])

      case PolicyAttributeEnum.Csrf_Prevention:
        return PoliciesConfiguration.aToggle(
          id,
          undefined,
          [PolicyAttributeEnum.Ca_Certificate],
          PoliciesConfiguration.updateCaCertificate
        )

      case PolicyAttributeEnum.Ws_Discovery:
        return PoliciesConfiguration.aToggle(
          id,
          undefined,
          [PolicyAttributeEnum.Ws_Print],
          PoliciesConfiguration.updateWsPrint
        )

      case PolicyAttributeEnum.Bonjour:
        return PoliciesConfiguration.aToggle(
          id,
          undefined,
          [PolicyAttributeEnum.Airprint],
          PoliciesConfiguration.updateAirPrint
        )

      case PolicyAttributeEnum.Tcpip_Print:
        return PoliciesConfiguration.aToggle(id, undefined, [
          PolicyAttributeEnum.PJL_Password
        ])

      case PolicyAttributeEnum.Ipp:
        return PoliciesConfiguration.aToggle(
          id,
          undefined,
          [PolicyAttributeEnum.Ipps, PolicyAttributeEnum.Airprint],
          PoliciesConfiguration.updateAirPrint
        )

      case PolicyAttributeEnum.Ipps:
        return PoliciesConfiguration.aToggle(
          id,
          undefined,
          [PolicyAttributeEnum.Ipp, PolicyAttributeEnum.Airprint],
          PoliciesConfiguration.updateAirPrint
        )

      case PolicyAttributeEnum.Ews_Access:
        return PoliciesConfiguration.aToggle(id, undefined, [
          PolicyAttributeEnum.EWS_Admin_Password
        ])

      case PolicyAttributeEnum.Dhcp_v4_Compliance:
      case PolicyAttributeEnum.Bt_Low_Energy:
        return {
          id,
          deviceSettings: [
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'check',
              help: 'check_help',
              attribute: `${id}.check`
            }
          ]
        }

      case PolicyAttributeEnum.Cartridge_Policy:
        return {
          id,
          deviceSettings: [
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'check',
              attribute: `${id}.genuine`
            }
          ]
        }

      case PolicyAttributeEnum.Cartridge_Protection:
        return {
          id,
          deviceSettings: [
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'check',
              warning: {
                value: true,
                message: 'info_message',
                messageType: MessageTypesEnum.INFO
              },
              attribute: `${id}.check`
            }
          ]
        }

      case PolicyAttributeEnum.Airprint_Fax:
      case PolicyAttributeEnum.Airprint_Scan:
      case PolicyAttributeEnum.Airprint_Secure_Scan:
      case PolicyAttributeEnum.Airprint_Scan_Secure_Scan:
        return {
          id,
          relatedItems: [PolicyAttributeEnum.Airprint],
          deviceSettings: [
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'check',
              help: 'check_help',
              disabledHelp: 'disabled_help',
              attribute: `${id}.check`,
              update: (value, enableCallback, setValueCallback, attributes) =>
                PoliciesConfiguration.resetChecks(
                  PoliciesConfiguration.getValue(
                    attributes,
                    PolicyAttributeEnum.Airprint,
                    `${PolicyAttributeEnum.Airprint}.check`
                  ) !== false,
                  enableCallback,
                  setValueCallback,
                  [`${id}.check`]
                )
            }
          ]
        }

      // Dropdowns

      case PolicyAttributeEnum.Def_Media_Size:
        return PoliciesConfiguration.aDropdown(
          id,
          PrintMediaItems.mediaSizes(constraints),
          'media-size',
          true
        )

      case PolicyAttributeEnum.Def_Media_Type:
        return PoliciesConfiguration.aDropdown(
          id,
          PrintMediaItems.mediaTypes(constraints),
          'media-type',
          true
        )

      case PolicyAttributeEnum.Control_Panel_Language:
        return PoliciesConfiguration.aDropdown(
          id,
          LanguageItems.languageCodes(constraints),
          'code',
          true
        )

      case PolicyAttributeEnum.Link_Setting:
        return PoliciesConfiguration.aDropdown(
          id,
          LinkSettingItems.modeCodes(constraints),
          'mode',
          true
        )

      case PolicyAttributeEnum.Input_Auto_Continue:
        return PoliciesConfiguration.aDropdown(
          id,
          DropboxItems.inputAutoContinueCodes,
          'mode'
        )

      case PolicyAttributeEnum.Input_Auto_Continue_Timeout:
        return PoliciesConfiguration.aDropdown(
          id,
          MinuteItems.inputAutoContinueTimeoutCodes
        )

      case PolicyAttributeEnum.Copy_Tray:
        return PoliciesConfiguration.aDropdown(
          id,
          MediaTrayItems.copyTrays,
          'tray'
        )

      case PolicyAttributeEnum.Copy_Darkness:
        return PoliciesConfiguration.aDropdown(
          id,
          ImageSettingItems.imageDarknessItems,
          'mode'
        )

      case PolicyAttributeEnum.Copy_Contrast:
        return PoliciesConfiguration.aDropdown(
          id,
          ImageSettingItems.imageContrastItems,
          'mode'
        )

      case PolicyAttributeEnum.Copy_Bg_Cleanup:
        return PoliciesConfiguration.aDropdown(
          id,
          ImageSettingItems.imageBgCleanupItems,
          'mode'
        )

      case PolicyAttributeEnum.Copy_Sharpness:
        return PoliciesConfiguration.aDropdown(
          id,
          ImageSettingItems.imageSharpnessItems,
          'mode'
        )

      // Radio buttons

      case PolicyAttributeEnum.File_Erase_Mode:
        return PoliciesConfiguration.aDropdown(
          id,
          DropboxItems.fileEraseModes,
          'mode',
          true,
          PoliciesConfiguration.fileEraseModeWarnings,
          true
        )

      case PolicyAttributeEnum.Control_Panel_Lock:
        return PoliciesConfiguration.aDropdown(
          id,
          DropboxItems.ctrlPanelLockOptions,
          'option',
          false,
          PoliciesConfiguration.ctrlPanelLockOptionWarnings,
          true
        )

      case PolicyAttributeEnum.Disk_Encryption_Status:
        return PoliciesConfiguration.aDropdown(
          id,
          DropboxItems.diskEncryptionStatuses,
          'status',
          false,
          PoliciesConfiguration.diskEncryptionStatusWarnings,
          true
        )

      case PolicyAttributeEnum.Duplex_Binding:
        return PoliciesConfiguration.aDropdown(
          id,
          DuplexBindingItems.duplexModes(constraints),
          'mode',
          true,
          undefined,
          true
        )

      case PolicyAttributeEnum.Tcpip_Config:
        return PoliciesConfiguration.aRadio(
          id,
          DropboxItems.tcpipConfigModes,
          'mode'
        )

      case PolicyAttributeEnum.Use_Requested_Tray:
        return PoliciesConfiguration.aRadio(
          id,
          DropboxItems.useRequestedTrayModes,
          'mode'
        )

      case PolicyAttributeEnum.Manual_Feed:
        return PoliciesConfiguration.aRadio(
          id,
          DropboxItems.manualFeedModes,
          'mode'
        )

      case PolicyAttributeEnum.Manual_Feed_Prompt:
        return PoliciesConfiguration.aRadio(
          id,
          DropboxItems.manualFeedPromptModes,
          'mode'
        )

      case PolicyAttributeEnum.Very_Low_Action_Black:
      case PolicyAttributeEnum.Very_Low_Action_Color:
      case PolicyAttributeEnum.Very_Low_Action_Fuser_Kit:
      case PolicyAttributeEnum.Very_Low_Action_Transfer_Kit:
      case PolicyAttributeEnum.Very_Low_Action_Maintenance_Kit:
      case PolicyAttributeEnum.Very_Low_Action_Doc_Feeder_Kit:
      case PolicyAttributeEnum.Very_Full_Action_Toner_Collection:
        return PoliciesConfiguration.aRadio(
          id,
          SupplyActionItems.supplyActions,
          'action'
        )

      case PolicyAttributeEnum.Google_Cloud_Print:
        return PoliciesConfiguration.aRadio(
          id,
          DropboxItems.googleCloudPrintModes,
          'mode'
        )

      case PolicyAttributeEnum.Copy_Color_Mode:
        return PoliciesConfiguration.aRadio(
          id,
          CopyColorItems.copyColorModes(constraints),
          'mode'
        )

      // TextBoxes

      case PolicyAttributeEnum.Asset_Number:
        return PoliciesConfiguration.aText(
          id,
          PoliciesErrors.errorsAssetNumber(constraints)
        )
      case PolicyAttributeEnum.Company_Name:
        return PoliciesConfiguration.aText(
          id,
          PoliciesErrors.errorsCompanyName(constraints)
        )
      case PolicyAttributeEnum.Contact_Person:
        return PoliciesConfiguration.aText(
          id,
          PoliciesErrors.errorsContactPerson(constraints)
        )
      case PolicyAttributeEnum.Device_Location:
        return PoliciesConfiguration.aText(
          id,
          PoliciesErrors.errorsDeviceLocation(constraints)
        )
      case PolicyAttributeEnum.Device_Name:
        return PoliciesConfiguration.aText(
          id,
          PoliciesErrors.errorsDeviceName(constraints)
        )

      case PolicyAttributeEnum.Support_Contact:
      case PolicyAttributeEnum.System_Contact:
      case PolicyAttributeEnum.System_Location:
        return PoliciesConfiguration.aText(id, PoliciesErrors.errorsText32)

      case PolicyAttributeEnum.Drum_Threshold_Cyan:
      case PolicyAttributeEnum.Drum_Threshold_Magenta:
      case PolicyAttributeEnum.Drum_Threshold_Yellow:
      case PolicyAttributeEnum.Drum_Threshold_Black:
      case PolicyAttributeEnum.Cartridge_Threshold_Cyan:
      case PolicyAttributeEnum.Cartridge_Threshold_Magenta:
      case PolicyAttributeEnum.Cartridge_Threshold_Yellow:
      case PolicyAttributeEnum.Cartridge_Threshold_Black:
      case PolicyAttributeEnum.Maintenance_Kit_Threshold:
      case PolicyAttributeEnum.Transfer_Kit_Threshold:
      case PolicyAttributeEnum.Fuser_Kit_Threshold:
        return PoliciesConfiguration.aText(
          id,
          PoliciesErrors.errorsThreshold,
          true,
          false,
          true
        )

      case PolicyAttributeEnum.Outgoing_Servers:
      case PolicyAttributeEnum.Quick_Sets:
        return {
          id,
          deviceSettings: [
            {
              type: PolicyItemPrimitives.Dropbox,
              label: 'mode',
              items: DropboxItems.overwriteModes(id),
              attribute: `${id}.mode`,
              radio: true,
              update: (value, enableCallback, setCallback) =>
                PoliciesConfiguration.resetChecks(
                  value === `${id}.mode.append`,
                  enableCallback,
                  setCallback,
                  [`${id}.overwrite-existing`]
                )
            },
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'overwrite-existing',
              attribute: `${id}.overwrite-existing`,
              indent: 1
            },
            id === PolicyAttributeEnum.Quick_Sets
              ? {
                  type: PolicyItemPrimitives.QuickSets,
                  attribute: `${id}.sets`,
                  indent: 1,
                  constraints
                }
              : {
                  type: PolicyItemPrimitives.OutgoingServers,
                  attribute: `${id}.servers`,
                  indent: 1,
                  constraints
                }
          ]
        }

      case PolicyAttributeEnum.Control_Panel_Timeout:
        return PoliciesConfiguration.aText(
          id,
          PoliciesErrors.errorsTimeoutValue,
          false,
          true,
          true
        )

      case PolicyAttributeEnum.Sleep_Delay:
        return PoliciesConfiguration.aDropdown(
          id,
          MinuteItems.sleepInactivityItems
        )

      // Other attributes

      case PolicyAttributeEnum.Hp_Web_Svc:
        return {
          id,
          deviceSettings: [
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'check',
              attribute: `${id}.check`,
              update: (value, enableCallback, setCallback) =>
                PoliciesConfiguration.resetChecks(
                  value,
                  enableCallback,
                  setCallback,
                  [`${id}.eprint`, `${id}.print-apps`]
                )
            },
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'eprint',
              attribute: `${id}.eprint`,
              indent: 1
            },
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'print-apps',
              attribute: `${id}.print-apps`,
              indent: 1
            }
          ]
        }

      case PolicyAttributeEnum.PJL_Password:
        return {
          id,
          relatedItems: [
            PolicyAttributeEnum.PJL_Access_Commands,
            PolicyAttributeEnum.Tcpip_Print
          ],
          deviceSettings: [
            {
              type: PolicyItemPrimitives.PasswordView,
              label: 'value',
              hint: 'value',
              help: 'value_help',
              attribute: `${id}.value`
            }
          ]
        }

      case PolicyAttributeEnum.Remote_Cfg_Password:
        return {
          id,
          deviceSettings: [
            {
              type: PolicyItemPrimitives.ToggleView,
              label: 'check',
              attribute: `${id}.check`
            },
            {
              type: PolicyItemPrimitives.PasswordView,
              label: 'value',
              hint: 'value',
              help: 'value_help',
              attribute: `${id}.value`,
              indent: 1
            },
            {
              type: PolicyItemPrimitives.Textbox,
              label: 'min-length',
              hint: 'min-length',
              help: 'min-length_help',
              errors: PoliciesErrors.errorsMinPasswordLength(),
              attribute: `${id}.min-length`,
              indent: 1,
              widthStyle: ControlWidthEnum.REDUCED
            },
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'complexity',
              help: 'complexity_help',
              attribute: `${id}.complexity`,
              indent: 1
            },
            {
              type: PolicyItemPrimitives.Toggle,

              indent: 1,
              attribute: `${id}.lockout`,
              label: 'lockout',
              update: (value, enableCallback) =>
                enableCallback(
                  [
                    `${id}.max-attempts`,
                    `${id}.reset-after`,
                    `${id}.lockout-duration`
                  ],
                  value
                )
            },
            {
              type: PolicyItemPrimitives.Textbox,
              label: 'max-attempts',
              widthStyle: ControlWidthEnum.REDUCED,
              hint: 'max-attempts',
              help: 'max-attempts_help',
              indent: 2,
              errors: PoliciesErrors.errorsMaxAttempts,
              attribute: `${id}.max-attempts`
            },
            {
              type: PolicyItemPrimitives.Textbox,
              label: 'reset-after',
              widthStyle: ControlWidthEnum.REDUCED,
              hint: 'reset-after',
              help: 'reset-after_help',
              attribute: `${id}.reset-after`,
              errors: PoliciesErrors.errorsResetAfter,
              indent: 2
            },
            {
              type: PolicyItemPrimitives.Textbox,
              widthStyle: ControlWidthEnum.REDUCED,
              label: 'lockout-duration',
              hint: 'lockout-duration',
              help: 'lockout-duration_help',
              errors: PoliciesErrors.errorsLockoutDuration,
              attribute: `${id}.lockout-duration`,
              indent: 2
            }
          ]
        }

      case PolicyAttributeEnum.Svc_Access_Code:
        return {
          id,
          deviceSettings: [
            {
              type: PolicyItemPrimitives.PasswordView,
              label: 'value',
              hint: 'value',
              help: 'value_help',
              attribute: `${id}.value`
            }
          ]
        }

      case PolicyAttributeEnum.FS_Access_Protocols:
        return {
          id,
          deviceSettings: [
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'ps-check',
              attribute: `${id}.ps-check`
            },
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'pjl-check',
              attribute: `${id}.pjl-check`
            }
          ]
        }

      case PolicyAttributeEnum.WiFi:
        return {
          id,
          deviceSettings: [
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'connected',
              attribute: `${id}.connected`,
              update: (value, enableCallback, setCallback) =>
                PoliciesConfiguration.resetChecks(
                  value,
                  enableCallback,
                  setCallback,
                  [`${id}.secure`]
                )
            },
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'secure',
              attribute: `${id}.secure`,
              indent: 1
            }
          ]
        }

      case PolicyAttributeEnum.SNMP_V1_V2:
        return {
          id,
          relatedItems: [PolicyAttributeEnum.SNMP_V3],
          deviceSettings: [
            {
              type: PolicyItemPrimitives.Dropbox,
              label: 'permission',
              items: DropboxItems.snmpPermissions,
              warnings: PoliciesConfiguration.snmpPermissionWarnings,
              attribute: `${id}.permission`,
              radio: true,
              update: (value, enableCallback, setCallback) => {
                enableCallback(
                  [`${id}.read-password`, `${id}.read-write-password`],
                  value === `${id}.permission.read-write`
                )
                setCallback([
                  {
                    name: `${id}.def-public`,
                    value: (value === `${id}.permission.read-only`) + ''
                  }
                ])
              }
            },
            {
              type: PolicyItemPrimitives.PasswordView,
              label: 'read-password',
              hint: 'label',
              help: 'read-password_help',
              attribute: `${id}.read-password`,
              indent: 1
            },
            {
              type: PolicyItemPrimitives.PasswordView,
              label: 'read-write-password',
              hint: 'label',
              help: 'read-write-password_help',
              attribute: `${id}.read-write-password`,
              indent: 1
            },
            {
              type: PolicyItemPrimitives.ToggleView,
              label: 'def-public',
              help: 'def-public_help',
              attribute: `${id}.def-public`,
              indent: 1
            }
          ]
        }

      case PolicyAttributeEnum.EWS_Admin_Password:
        return {
          id,
          deviceSettings: [
            {
              type: PolicyItemPrimitives.ToggleView,
              label: 'check',
              attribute: `${PolicyAttributeEnum.EWS_Admin_Password}.check`
            },
            {
              type: PolicyItemPrimitives.PasswordView,
              label: 'value',
              hint: 'value',
              help: 'value_help',
              attribute: `${PolicyAttributeEnum.EWS_Admin_Password}.value`,
              indent: 1
            },
            {
              type: PolicyItemPrimitives.Textbox,
              label: 'min-length',
              hint: 'min-length',
              help: 'min-length_help',
              errors: PoliciesErrors.errorsMinPasswordLength(constraints),
              attribute: `${PolicyAttributeEnum.EWS_Admin_Password}.min-length`,
              indent: 1,
              widthStyle: ControlWidthEnum.REDUCED
            },
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'complexity',
              help: 'complexity_help',
              attribute: `${PolicyAttributeEnum.EWS_Admin_Password}.complexity`,
              indent: 1
            },
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'lockout',
              attribute: `${PolicyAttributeEnum.EWS_Admin_Password}.lockout`,
              indent: 1,
              update: (value, enableCallback) =>
                enableCallback(
                  [
                    `${PolicyAttributeEnum.EWS_Admin_Password}.max-attempts`,
                    `${PolicyAttributeEnum.EWS_Admin_Password}.reset-after`,
                    `${PolicyAttributeEnum.EWS_Admin_Password}.lockout-duration`
                  ],
                  value
                )
            },
            {
              type: PolicyItemPrimitives.Textbox,
              label: 'max-attempts',
              hint: 'max-attempts',
              help: 'max-attempts_help',
              errors: PoliciesErrors.errorsMaxAttempts,
              widthStyle: ControlWidthEnum.REDUCED,
              attribute: `${PolicyAttributeEnum.EWS_Admin_Password}.max-attempts`,
              indent: 2
            },
            {
              type: PolicyItemPrimitives.Textbox,
              label: 'reset-after',
              hint: 'reset-after',
              help: 'reset-after_help',
              errors: PoliciesErrors.errorsResetAfter,
              attribute: `${PolicyAttributeEnum.EWS_Admin_Password}.reset-after`,
              widthStyle: ControlWidthEnum.REDUCED,
              indent: 2
            },
            {
              type: PolicyItemPrimitives.Textbox,
              label: 'lockout-duration',
              widthStyle: ControlWidthEnum.REDUCED,
              hint: 'lockout-duration',
              help: 'lockout-duration_help',
              errors: PoliciesErrors.errorsLockoutDuration,
              attribute: `${PolicyAttributeEnum.EWS_Admin_Password}.lockout-duration`,
              indent: 2
            }
          ]
        }

      case PolicyAttributeEnum.SNMP_V3:
        return {
          id,
          relatedItems: [PolicyAttributeEnum.SNMP_V1_V2],
          deviceSettings: [
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'check',
              attribute: `${id}.check`,
              update: (value, enableCallback) =>
                enableCallback(
                  [
                    `${id}.user-name`,
                    `${id}.min-length`,
                    `${id}.complexity`,
                    `${id}.auth-passphrase`,
                    `${id}.privacy-passphrase`,
                    `${id}.encryption-algorithm`,
                    `${id}.lockout`,
                    `${id}.max-attempts`,
                    `${id}.reset-after`,
                    `${id}.lockout-duration`
                  ],
                  value
                )
            },
            {
              type: PolicyItemPrimitives.PasswordView,
              label: 'user-name',
              hint: 'user-name',
              help: 'user-name_help',
              attribute: `${id}.user-name`,
              indent: 1
            },
            {
              type: PolicyItemPrimitives.Textbox,
              label: 'min-length',
              hint: 'min-length',
              help: 'min-length_help',
              errors: PoliciesErrors.errorsMinSnmpPasswordLength,
              attribute: `${id}.min-length`,
              indent: 1,
              widthStyle: ControlWidthEnum.REDUCED
            },
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'complexity',
              help: 'complexity_help',
              attribute: `${id}.complexity`,
              indent: 1
            },
            {
              type: PolicyItemPrimitives.PasswordView,
              label: 'auth-passphrase',
              hint: 'auth-passphrase_hint',
              help: 'auth-passphrase_help',
              attribute: `${id}.auth-passphrase`,
              indent: 1
            },
            {
              type: PolicyItemPrimitives.PasswordView,
              label: 'privacy-passphrase',
              hint: 'privacy-passphrase_hint',
              help: 'privacy-passphrase_help',
              attribute: `${id}.privacy-passphrase`,
              indent: 1
            },
            {
              type: PolicyItemPrimitives.Dropbox,
              label: 'encryption-algorithm',
              help: 'encryption-algorithm_help',
              items: DropboxItems.snmpEncryptionAlgorithms,
              attribute: `${id}.encryption-algorithm`,
              indent: 1
            },
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'lockout',
              attribute: `${id}.lockout`,
              indent: 1,
              update: (value, enableCallback) =>
                enableCallback(
                  [
                    `${id}.max-attempts`,
                    `${id}.reset-after`,
                    `${id}.lockout-duration`
                  ],
                  value
                )
            },
            {
              type: PolicyItemPrimitives.Textbox,
              label: 'max-attempts',
              hint: 'max-attempts',
              help: 'max-attempts_help',
              widthStyle: ControlWidthEnum.REDUCED,
              errors: PoliciesErrors.errorsMaxAttempts,
              attribute: `${id}.max-attempts`,
              indent: 2
            },
            {
              type: PolicyItemPrimitives.Textbox,
              label: 'reset-after',
              hint: 'reset-after',
              help: 'reset-after_help',
              widthStyle: ControlWidthEnum.REDUCED,
              errors: PoliciesErrors.errorsResetAfter,
              attribute: `${id}.reset-after`,
              indent: 2
            },
            {
              type: PolicyItemPrimitives.Textbox,
              label: 'lockout-duration',
              hint: 'lockout-duration',
              help: 'lockout-duration_help',
              errors: PoliciesErrors.errorsLockoutDuration,
              widthStyle: ControlWidthEnum.REDUCED,
              attribute: `${id}.lockout-duration`,
              indent: 2
            }
          ]
        }

      case PolicyAttributeEnum.Retain_Print_Jobs:
        return {
          id,
          deviceSettings: [
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'check',
              attribute: `${id}.check`,
              update: (value, enableCallback) =>
                enableCallback([`${id}.temporary`, `${id}.standard`], value)
            },
            {
              type: PolicyItemPrimitives.Dropbox,
              label: 'temp-stored',
              items: MinuteItems.jobRetentionPeriods,
              attribute: `${id}.temporary`,
              indent: 1
            },
            {
              type: PolicyItemPrimitives.Dropbox,
              label: 'standard',
              items: MinuteItems.jobRetentionPeriods,
              attribute: `${id}.standard`,
              indent: 1
            }
          ]
        }

      case PolicyAttributeEnum.Check_Latest_FW:
        return {
          id,
          deviceSettings: [
            {
              type: PolicyItemPrimitives.ToggleView,
              label: 'check',
              attribute: `${id}.check`,
              update: (value, enableCallback) =>
                enableCallback([`${id}.file-source`], value)
            },
            {
              type: PolicyItemPrimitives.Dropbox,
              label: 'file-source',
              help: 'file-source_help',
              items: DropboxItems.latestFwSources,
              attribute: `${id}.file-source`,
              indent: 1
            }
          ]
        }

      case PolicyAttributeEnum.Copy_Optimize:
      case PolicyAttributeEnum.Email_Scan:
        return {
          id,
          deviceSettings: [
            {
              type: PolicyItemPrimitives.Dropbox,
              label: 'optimize-text-picture',
              items: PrintOptimizeImageItems.optimizeImageTypes,
              attribute: `${id}.optimize-text-picture`,
              radio: true
            }
          ]
        }

      case PolicyAttributeEnum.Email_Notification:
      case PolicyAttributeEnum.Network_Folder_Notification:
        return PoliciesConfiguration.aNotification(id)

      case PolicyAttributeEnum.Airprint:
        return {
          id,
          relatedItems: [
            PolicyAttributeEnum.Bonjour,
            PolicyAttributeEnum.Ipp,
            PolicyAttributeEnum.Ipps
          ],
          deviceSettings: [
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'check',
              disabledHelp: 'disabled_help',
              attribute: `${id}.check`,
              update: (value, enableCallback, setValueCallback, attributes) => {
                PoliciesConfiguration.updateAirPrint(
                  value,
                  enableCallback,
                  setValueCallback,
                  attributes
                ) // self-update
                PoliciesConfiguration.resetChecks(
                  value,
                  enableCallback,
                  setValueCallback,
                  [
                    `${PolicyAttributeEnum.Airprint_Fax}.check`,
                    `${PolicyAttributeEnum.Airprint_Scan}.check`,
                    `${PolicyAttributeEnum.Airprint_Secure_Scan}.check`,
                    `${PolicyAttributeEnum.Airprint_Scan_Secure_Scan}.check`
                  ]
                )
              }
            }
          ]
        }

      case PolicyAttributeEnum.Ws_Print:
        return {
          id,
          relatedItems: [PolicyAttributeEnum.Ws_Discovery],
          deviceSettings: [
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'check',
              disabledHelp: 'disabled_help',
              attribute: `${id}.check`,
              update: PoliciesConfiguration.updateWsPrint // self-update
            }
          ]
        }

      case PolicyAttributeEnum.Web_Encryption:
        return {
          id,
          deviceSettings: [
            {
              type: PolicyItemPrimitives.Dropbox,
              label: 'strength',
              items: WebEncryptionItems.webEncryptionStrengths,
              attribute: `${id}.strength`,
              radio: true
            },
            {
              type: PolicyItemPrimitives.WebEncryption,
              attribute: `${id}`,
              constraints
            }
          ]
        }

      case PolicyAttributeEnum.Host_Usb_Pnp:
        return {
          id,
          deviceSettings: [
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'check',
              attribute: `${id}.check`,
              update: (value, enableCallback, setCallback) =>
                PoliciesConfiguration.resetChecks(
                  value,
                  enableCallback,
                  setCallback,
                  [`${id}.retrieve`, `${id}.save`]
                )
            },
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'retrieve',
              attribute: `${id}.retrieve`,
              indent: 1
            },
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'save',
              attribute: `${id}.save`,
              indent: 1
            }
          ]
        }

      case PolicyAttributeEnum.Secure_Boot_Presence:
        return {
          id,
          deviceSettings: [
            {
              type: PolicyItemPrimitives.ToggleView,
              label: 'check',
              help: 'secure-boot-presence_help',
              attribute: `${id}.check`
            }
          ]
        }

      case PolicyAttributeEnum.Intrusion_Detection_Presence:
        return {
          id,
          deviceSettings: [
            {
              type: PolicyItemPrimitives.ToggleView,
              label: 'check',
              help: 'intrusion-detect-presence_help',
              attribute: `${id}.check`
            }
          ]
        }

      case PolicyAttributeEnum.Whitelisting_Presence:
        return {
          id,
          deviceSettings: [
            {
              type: PolicyItemPrimitives.ToggleView,
              label: 'check',
              help: 'whitelist-presence_help',
              attribute: `${id}.check`
            }
          ]
        }

      case PolicyAttributeEnum.Fax_Receive:
        return {
          id,
          relatedItems: [
            PolicyAttributeEnum.Fax_Header,
            PolicyAttributeEnum.IP_Fax
          ],
          deviceSettings: [
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'check',
              attribute: `${id}.check`,
              update: (value, enableCallback) =>
                enableCallback([`${id}.owner`, `${id}.settings`], value)
            },
            {
              type: PolicyItemPrimitives.Dropbox,
              label: 'owner',
              help: 'owner_help',
              items: FaxReceiveItems.faxReceiveOwners,
              attribute: `${id}.owner`,
              radio: true,
              indent: 1
            },
            {
              type: PolicyItemPrimitives.FaxReceive,
              attribute: `${id}.settings`,
              indent: 1,
              constraints
            }
          ]
        }

      case PolicyAttributeEnum.Fax_Send:
        return {
          id,
          relatedItems: [
            PolicyAttributeEnum.Fax_Header,
            PolicyAttributeEnum.IP_Fax
          ],
          deviceSettings: [
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'check',
              attribute: `${id}.check`,
              update: (value, enableCallback) =>
                enableCallback([`${id}.settings`], value)
            },
            {
              type: PolicyItemPrimitives.FaxSend,
              attribute: `${id}.settings`,
              indent: 1,
              constraints
            }
          ]
        }

      case PolicyAttributeEnum.Info_Tab:
        return {
          id,
          deviceSettings: [
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'require-password',
              attribute: `${id}.require-password`
            },
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'display-print-page',
              attribute: `${id}.display-print-page`
            },
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'display-job-log',
              attribute: `${id}.display-job-log`
            }
          ]
        }

      case PolicyAttributeEnum.Firmware_Update:
        return {
          id,
          deviceSettings: [
            {
              type: PolicyItemPrimitives.FwUpdateVersion,
              model: constraints?.modelName,
              attribute: `${id}.versions`
            },
            {
              type: PolicyItemPrimitives.FwUpdateSchedule,
              label: 'schedule',
              help: 'schedule_help',
              attribute: `${id}.schedule`
            }
          ]
        }

      case PolicyAttributeEnum.Ipv4_Info:
        return {
          id,
          deviceSettings: [
            {
              type: PolicyItemPrimitives.Textbox,
              label: 'ip-address',
              hint: 'ip-address_hint',
              errors: PoliciesErrors.errorsIpv4,
              attribute: `${id}.ip-address`,
              widthStyle: ControlWidthEnum.REDUCED
            },
            {
              type: PolicyItemPrimitives.Textbox,
              label: 'subnet-mask',
              hint: 'subnet-mask_hint',
              errors: PoliciesErrors.errorsIpv4,
              attribute: `${id}.subnet-mask`,
              widthStyle: ControlWidthEnum.REDUCED
            },
            {
              type: PolicyItemPrimitives.Textbox,
              label: 'gateway',
              hint: 'gateway_hint',
              errors: PoliciesErrors.errorsIpv4,
              attribute: `${id}.gateway`,
              widthStyle: ControlWidthEnum.REDUCED
            }
          ]
        }

      case PolicyAttributeEnum.Date_Time_Format:
        return {
          id,
          deviceSettings: [
            {
              type: PolicyItemPrimitives.Dropbox,
              label: 'date',
              help: 'date_help',
              items: DropboxItems.dateFormats,
              attribute: `${id}.date`,
              radio: true
            },
            {
              type: PolicyItemPrimitives.Dropbox,
              label: 'time',
              help: 'time_help',
              items: DropboxItems.timeFormats,
              attribute: `${id}.time`,
              radio: true
            }
          ]
        }

      case PolicyAttributeEnum.Ews_Language: {
        const isMode =
          constraints?.isPolicyAttributeSettingSupported(id, `${id}.mode`) ??
          true
        return {
          id,
          deviceSettings: [
            {
              type: PolicyItemPrimitives.Dropbox,
              label: 'mode',
              help: 'mode_help',
              items: DropboxItems.ewsLanguageModes,
              attribute: `${id}.mode`,
              radio: true,
              update: (value, enableCallback) =>
                enableCallback([`${id}.code`], value === `${id}.mode.select`)
            },
            {
              type: PolicyItemPrimitives.Dropbox,
              label: isMode ? 'code' : 'mode',
              items: LanguageItems.languageCodesEws(constraints),
              attribute: `${id}.code`,
              indent: +isMode
            }
          ]
        }
      }

      case PolicyAttributeEnum.Dns_Server:
        return {
          id,
          deviceSettings: [
            {
              type: PolicyItemPrimitives.Textbox,
              label: 'primary',
              hint: 'primary_hint',
              errors: PoliciesErrors.errorsIpv4,
              attribute: `${id}.primary`
            },
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'primary-reset',
              indent: 1,
              update: (value, enableCallback, setCallback) =>
                PoliciesConfiguration.updateDnsServer(
                  value,
                  enableCallback,
                  setCallback,
                  `${id}.primary`
                ),
              attribute: `${id}.primary-reset`
            },
            {
              type: PolicyItemPrimitives.Textbox,
              label: 'secondary',
              hint: 'secondary_hint',
              errors: PoliciesErrors.errorsIpv4,
              attribute: `${id}.secondary`
            },
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'secondary-reset',
              indent: 1,
              update: (value, enableCallback, setCallback) =>
                PoliciesConfiguration.updateDnsServer(
                  value,
                  enableCallback,
                  setCallback,
                  `${id}.secondary`
                ),
              attribute: `${id}.secondary-reset`
            },
            {
              type: PolicyItemPrimitives.Textbox,
              label: 'domain',
              hint: 'domain_hint',
              errors: PoliciesErrors.errorsDnsDomainName,
              attribute: `${id}.domain`
            }
          ]
        }

      case PolicyAttributeEnum.Time_Zone:
        return {
          id,
          deviceSettings: [
            {
              type: PolicyItemPrimitives.ObjectDropBox,
              label: 'zone',
              help: 'zone_help',
              items: TimeZoneItems.timeZoneCodes,
              attribute: `${id}.zone`,
              update: (value, enableCallback, setCallback) =>
                PoliciesConfiguration.resetChecks(
                  !!value[dstEnum.DST_OFFSET],
                  enableCallback,
                  setCallback,
                  [`${id}.auto-dst`]
                )
            },
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'auto-dst',
              help: 'auto-dst_help',
              attribute: `${id}.auto-dst`,
              indent: 1
            }
          ]
        }

      case PolicyAttributeEnum.Ipv6_Info:
        return {
          id,
          deviceSettings: [
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'enable',
              help: 'enable_help',
              attribute: `${id}.check`,
              update: (value, enableCallback) =>
                enableCallback(
                  [
                    `${id}.dhcp-policy`,
                    `${id}.domain`,
                    `${id}.primary-dns`,
                    `${id}.secondary-dns`
                  ],
                  value
                )
            },
            {
              type: PolicyItemPrimitives.Dropbox,
              label: 'dhcp',
              items: DropboxItems.ipv6DhcpPolicyCodes,
              attribute: `${id}.dhcp-policy`,
              radio: true,
              indent: 1
            },
            {
              type: PolicyItemPrimitives.Textbox,
              label: 'domain',
              hint: 'domain_hint',
              errors: PoliciesErrors.errorsIpv6Domain,
              attribute: `${id}.domain`,
              indent: 1
            },
            {
              type: PolicyItemPrimitives.Textbox,
              label: 'primary_dns',
              hint: 'primary_dns_hint',
              errors: PoliciesErrors.errorsIpv6,
              attribute: `${id}.primary-dns`,
              indent: 1
            },
            {
              type: PolicyItemPrimitives.Textbox,
              label: 'secondary_dns',
              hint: 'secondary_dns_hint',
              errors: PoliciesErrors.errorsIpv6,
              attribute: `${id}.secondary-dns`,
              indent: 1
            }
          ]
        }

      case PolicyAttributeEnum.Configuration_Precedence:
        return {
          id,
          deviceSettings: [
            {
              type: PolicyItemPrimitives.PriorityList,
              label: 'order',
              help: 'order_help',
              items:
                ConfigurationPrecedence.configurationPrecedenceCodes(
                  constraints
                ),
              attribute: `${id}.order`
            }
          ]
        }

      case PolicyAttributeEnum.Time_Services:
        return {
          id,
          deviceSettings: [
            {
              type: PolicyItemPrimitives.Dropbox,
              label: 'drift-correction',
              help: 'drift-correction_help',
              items: DropboxItems.timeServicesCodes,
              attribute: `${id}.drift-correction`,
              radio: true,
              update: (value, enableCallback) => {
                enableCallback(
                  [`${id}.server-address`, `${id}.port`, `${id}.sync-hours`],
                  value === `${id}.drift-correction.ntp`
                )
              }
            },
            {
              type: PolicyItemPrimitives.Textbox,
              attribute: `${id}.server-address`,
              label: 'server-address',
              hint: 'server-address_hint',
              errors: PoliciesErrors.errorsNotSelected,
              indent: 1
            },
            {
              type: PolicyItemPrimitives.Textbox,
              attribute: `${id}.port`,
              label: 'port',
              hint: 'port_hint',
              errors: PoliciesErrors.errorsNtpPortNumber,
              indent: 1
            },
            {
              type: PolicyItemPrimitives.Textbox,
              attribute: `${id}.sync-hours`,
              label: 'sync-hours',
              hint: 'sync-hours_hint',
              errors: PoliciesErrors.errorsSyncServerTime,
              indent: 1
            }
          ]
        }

      case PolicyAttributeEnum.Delay_Low_Supply_Msg:
        return {
          id,
          deviceSettings: [
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'check',
              attribute: `${id}.check`,
              update: (value, enableCallback) =>
                enableCallback([`${id}.threshold`], value)
            },
            {
              type: PolicyItemPrimitives.DelayLowMsgThreshold,
              attribute: `${id}.threshold`,
              indent: 1
            }
          ]
        }

      case PolicyAttributeEnum.Tray_Admin:
        return {
          id,
          deviceSettings: [
            {
              type: PolicyItemPrimitives.TrayAdministration,
              trays: MediaTrayItems.trayAdminTrays,
              sizes: PrintMediaItems.mediaSizes(constraints),
              types: PrintMediaItems.mediaTypes(constraints),
              attribute: `${id}`
            }
          ]
        }

      case PolicyAttributeEnum.Home_Screen_Language:
        return {
          id,
          deviceSettings: [
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'check',
              attribute: `${id}.check`
            },
            {
              type: PolicyItemPrimitives.HomeScreenLanguageAndKeyboard,
              items: LanguageItems.languageCodes(constraints),
              attribute: `${id}.kb-layouts`
            },
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'alt',
              attribute: `${id}.alt`,
              update: (value, enableCallback) =>
                enableCallback([`${id}.alt-kb-layouts`], value)
            },
            {
              type: PolicyItemPrimitives.MultiSelectCheckbox,
              error: 'alt-kb-layouts-not-selected-error',
              select: 'select-alt-kb-layouts',
              change: 'change-alt-kb-layouts',
              description: 'alt-kb-layouts-modal-description',
              selected: 'selected-items-count',
              tableColumn: 'home-screen-language-table-column-kb-layout',
              items: HomeScreenLanguageItems.keyboardLayouts,
              attribute: `${id}.alt-kb-layouts`,
              indent: 1
            }
          ]
        }

      case PolicyAttributeEnum.Digital_Sending:
        return {
          id,
          deviceSettings: [
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'use',
              attribute: `${id}.use`,
              update: (value, enableCallback, setCallback) =>
                PoliciesConfiguration.resetChecks(
                  value,
                  enableCallback,
                  setCallback,
                  [`${id}.transfer`]
                )
            },
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'transfer',
              attribute: `${id}.transfer`,
              indent: 1
            }
          ]
        }

      case PolicyAttributeEnum.Hp_Jet_Adv:
        return {
          id,
          deviceSettings: [
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'check',
              attribute: `${id}.check`
            },
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'create-account',
              attribute: `${id}.create-account`
            }
          ]
        }

      case PolicyAttributeEnum.Device_Announcement:
        return {
          id,
          deviceSettings: [
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'check',
              attribute: `${id}.check`,
              update: (value, enableCallback, setCallback) => {
                const address = `${id}.ip-address`
                const auth = `${id}.mutual-auth`
                enableCallback([address, auth], value)
                if (!value) {
                  setCallback([
                    { name: address, value: '' },
                    { name: auth, value: sFalse }
                  ])
                }
              }
            },
            {
              type: PolicyItemPrimitives.Textbox,
              label: 'ip-address',
              widthStyle: ControlWidthEnum.REDUCED,
              hint: 'ip-address_hint',
              errors: PoliciesErrors.errorsIpv4v6Empty,
              attribute: `${id}.ip-address`,
              help: 'ip-address_help',
              indent: 1
            },
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'mutual-auth',
              attribute: `${id}.mutual-auth`,
              indent: 1
            }
          ]
        }

      case PolicyAttributeEnum.Online_Solutions:
        return {
          id,
          deviceSettings: [
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'show-qr-code',
              attribute: `${id}.show-qr-code`
            },
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'show-links',
              attribute: `${id}.show-links`
            },
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'admin-only',
              help: 'admin-only_help',
              attribute: `${id}.admin-only`
            }
          ]
        }

      case PolicyAttributeEnum.Sleep_Settings:
        return {
          id,
          deviceSettings: [
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'check',
              attribute: `${id}.check`,
              update: (value, enableCallback, _, attributes) =>
                PoliciesConfiguration.updateSleepSettings(
                  value,
                  enableCallback,
                  attributes,
                  [...sleepSettingsValues, `${id}.on-events`]
                )
            },
            {
              type: PolicyItemPrimitives.Textbox,
              label: 'value',
              hint: 'value_hint',
              errors: PoliciesErrors.errorsSleepDelay,
              widthStyle: ControlWidthEnum.REDUCED,
              attribute: `${id}.value`,
              update: (value, enableCallback, _, attributes) =>
                PoliciesConfiguration.updateSleepSettings(
                  value,
                  enableCallback,
                  attributes
                ),
              indent: 1
            },
            {
              type: PolicyItemPrimitives.Dropbox,
              label: 'on-events',
              items: WakeOnItems.wakeOnEvents(constraints),
              attribute: `${id}.on-events`,
              radio: true,
              warnings: PoliciesConfiguration.wakeOnEventsInfoMsgs,
              indent: 1
            },
            {
              type: PolicyItemPrimitives.Textbox,
              label: 'off-value',
              hint: 'value_hint',
              widthStyle: ControlWidthEnum.REDUCED,
              errors: PoliciesErrors.errorsSleepDelayOff,
              attribute: `${id}.off-value`,
              update: (value, enableCallback, _, attributes) =>
                PoliciesConfiguration.updateSleepSettings(
                  value,
                  enableCallback,
                  attributes
                ),
              indent: 1
            },
            {
              type: PolicyItemPrimitives.Error,
              error: 'sum-error',
              params: { sum: sleepSettingsLimit },
              attribute: id,
              indent: 1
            }
          ]
        }

      case PolicyAttributeEnum.Fax_Header:
        return {
          id,
          deviceSettings: [
            {
              type: PolicyItemPrimitives.Textbox,
              label: 'phone-number',
              hint: 'phone-number_hint',
              errors: PoliciesErrors.errorsPhoneNumber,
              attribute: `${id}.phone-number`
            },
            {
              type: PolicyItemPrimitives.Textbox,
              label: 'company-name',
              hint: 'company-name_hint',
              errors: PoliciesErrors.errorsFaxCompanyName,
              attribute: `${id}.company-name`
            },
            {
              type: PolicyItemPrimitives.Dropbox,
              label: 'location',
              items: CountryItems.countryCodes(constraints),
              attribute: `${id}.location`
            }
          ]
        }

      case PolicyAttributeEnum.Proxy_Server:
        return {
          id,
          deviceSettings: [
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'check',
              attribute: `${id}.check`,
              update: (value, enableCallback) =>
                enableCallback([`${id}.address`, `${id}.port`], value)
            },
            {
              type: PolicyItemPrimitives.Textbox,
              label: 'address',
              hint: 'address_hint',
              errors: PoliciesErrors.errorsProxyAddress(constraints),
              attribute: `${id}.address`,
              indent: 1
            },
            {
              type: PolicyItemPrimitives.Textbox,
              label: 'port',
              hint: 'port_hint',
              errors: PoliciesErrors.errorsPortNumber,
              attribute: `${id}.port`,
              indent: 1
            }
          ]
        }

      case PolicyAttributeEnum.Restrict_Color:
        return {
          id,
          deviceSettings: [
            {
              type: PolicyItemPrimitives.Dropbox,
              label: 'mode',
              items: DropboxItems.restrictColorModes,
              attribute: `${id}.mode`,
              radio: true,
              update: (value, enableCallback) =>
                enableCallback(
                  restrictColorChecks,
                  value === `${id}.mode.custom-access`
                )
            },
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'user',
              attribute: `${id}.user`,
              indent: 1,
              update: (value, enableCallback, _, attributes) =>
                PoliciesConfiguration.updateRestrictColor(
                  value,
                  enableCallback,
                  attributes
                )
            },
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'app',
              attribute: `${id}.app`,
              indent: 1,
              update: (value, enableCallback, _, attributes) =>
                PoliciesConfiguration.updateRestrictColor(
                  value,
                  enableCallback,
                  attributes,
                  [`${id}.def-app-perm`, `${id}.app-perm`]
                )
            },
            {
              type: PolicyItemPrimitives.Dropbox,
              label: 'def-app-perm',
              items: DropboxItems.restrictDefaultAppParams,
              attribute: `${id}.def-app-perm`,
              radio: true,
              indent: 2
            },
            {
              type: PolicyItemPrimitives.RestrictColor,
              attribute: `${id}.app-perm`,
              indent: 2
            },
            {
              type: PolicyItemPrimitives.Error,
              error: 'not-selected-error',
              attribute: id,
              indent: 1
            }
          ]
        }

      case PolicyAttributeEnum.Smtp_Gateway:
        return {
          id,
          deviceSettings: [
            {
              type: PolicyItemPrimitives.Textbox,
              label: 'gateway',
              hint: 'enter-name-or-address',
              errors: PoliciesErrors.errorsNotSelected,
              attribute: `${id}.gateway`
            },
            {
              type: PolicyItemPrimitives.Textbox,
              label: 'port',
              hint: 'enter-port-number',
              errors: PoliciesErrors.errorsPortNumber,
              attribute: `${id}.port`
            },
            {
              type: PolicyItemPrimitives.Dropbox,
              label: 'attach-size',
              items: BytesOutputItems.smtpBytesSizeItems,
              attribute: `${id}.attach-size`
            },
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'auth',
              attribute: `${id}.auth`,
              update: (value, enableCallback) => {
                const credId = `${id}.credential`
                enableCallback([`${credId}_${credId}.user`], value)
                enableCallback([`${credId}_${credId}.public`], false)
              }
            },
            {
              type: PolicyItemPrimitives.Dropbox,
              items: DropboxItems.userAuthenticationModes,
              attribute: `${id}.credential`,
              radio: true,
              indent: 1
            }
          ]
        }

      case PolicyAttributeEnum.Def_Print_Options:
        return {
          id,
          deviceSettings: [
            {
              type: PolicyItemPrimitives.Dropbox,
              label: 'staple',
              items: DefPrintOptions.stapleCodes,
              attribute: `${PolicyAttributeEnum.Def_Print_Options}.staple`
            },
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'staples',
              attribute: `${PolicyAttributeEnum.Def_Print_Options}.staples`
            },
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'job-offset',
              attribute: `${PolicyAttributeEnum.Def_Print_Options}.job-offset`
            },
            {
              type: PolicyItemPrimitives.Dropbox,
              label: 'hole-punch',
              items: DefPrintOptions.holePunchCodes,
              attribute: `${PolicyAttributeEnum.Def_Print_Options}.hole-punch`
            },
            {
              type: PolicyItemPrimitives.Dropbox,
              label: 'output-bin',
              items: DefPrintOptions.outputBinCodes,
              attribute: `${PolicyAttributeEnum.Def_Print_Options}.output-bin`
            }
          ]
        }

      case PolicyAttributeEnum.Email_Message:
        return {
          id,
          deviceSettings: [
            {
              type: PolicyItemPrimitives.Dropbox,
              label: 'restrictions',
              items: EmailAddressItems.restrictionItems,
              attribute: `${PolicyAttributeEnum.Email_Message}.restrictions`,
              radio: true
            },
            {
              type: PolicyItemPrimitives.EmailMessage,
              attribute: `${PolicyAttributeEnum.Email_Message}.message`,
              constraints
            },
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'allow-invalid-email',
              attribute: `${PolicyAttributeEnum.Email_Message}.allow-invalid-email`
            },
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'sign',
              attribute: `${PolicyAttributeEnum.Email_Message}.sign`
            },
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'user-editable',
              attribute: `${PolicyAttributeEnum.Email_Message}.sign-editable`,
              indent: 1
            },
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'encrypt',
              attribute: `${PolicyAttributeEnum.Email_Message}.encrypt`
            },
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'user-editable',
              attribute: `${PolicyAttributeEnum.Email_Message}.encrypt-editable`,
              indent: 1
            }
          ]
        }

      case PolicyAttributeEnum.Energy_Settings:
        return {
          id,
          deviceSettings: [
            {
              type: PolicyItemPrimitives.Dropbox,
              label: 'off-inactivity',
              items: MinuteItems.sleepInactivityItems,
              attribute: `${id}.off-inactivity`
            },
            {
              type: PolicyItemPrimitives.Dropbox,
              label: 'shut-down-inactivity',
              items: MinuteItems.shutdownInactivityItems(constraints),
              attribute: `${id}.shut-down-inactivity`
            },
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'delay-ports-active',
              attribute: `${id}.delay-ports-active`
            }
          ]
        }

      case PolicyAttributeEnum.Enforce_Stamps:
        return {
          id,
          deviceSettings: [
            {
              type: PolicyItemPrimitives.CopyStamps,
              attribute: `${id}.stamps`
            }
          ]
        }

      case PolicyAttributeEnum.Auto_Send:
        return {
          id,
          deviceSettings: [
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'check',
              attribute: `${id}.check`,
              update: (value, enableCallback, setValueCallBack) => {
                enableCallback([...autoSendChecks, `${id}.every`], value)
                if (!value) {
                  setValueCallBack(
                    autoSendChecks.map((name) => ({ name, value: sFalse }))
                  )
                }
              }
            },
            {
              type: PolicyItemPrimitives.RadioTextBox,
              attribute: `${id}.every`,
              radioKey: 'unit',
              textKey: 'value',
              indent: 1,
              label: 'every-unit',
              items: DropboxItems.autoSendItems,
              widthStyle: ControlWidthEnum.REDUCED
            },
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'web-hp',
              attribute: `${id}.web-hp`,
              indent: 1,
              update: (value, enableCallback, setValueCallBack, attributes) => {
                const emailHp = `${id}.email-hp`
                PoliciesConfiguration.updateAutoSend(
                  value,
                  enableCallback,
                  attributes,
                  emailHp
                )
                if (!value) {
                  setValueCallBack([{ name: emailHp, value: sFalse }])
                }
              }
            },
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'email-hp',
              attribute: `${id}.email-hp`,
              indent: 2
            },
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'web',
              attribute: `${id}.web`,
              indent: 1,
              update: (value, enableCallback, _, attributes) =>
                PoliciesConfiguration.updateAutoSend(
                  value,
                  enableCallback,
                  attributes,
                  `${id}.web-addresses`
                )
            },
            {
              label: 'web-addresses-label',
              type: PolicyItemPrimitives.SimpleTable,
              attribute: `${id}.web-addresses`,
              indent: 2,
              limit: 5,
              errors: PoliciesErrors.errorsHttpsDomainName,
              countLabel: 'web-addresses-limit',
              tableColumn: 'web-addresses-column',
              modalDescription: 'web-addresses-description',
              modalTextLabel: 'web-addresses-modal-text-label',
              modalHelperText: 'helper-text',
              duplicateError: 'duplicate-error'
            },
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'email',
              attribute: `${id}.email`,
              indent: 1,
              update: (value, enableCallback, _, attributes) =>
                PoliciesConfiguration.updateAutoSend(
                  value,
                  enableCallback,
                  attributes,
                  `${id}.email-addresses`
                )
            },
            {
              label: 'email-addresses-label',
              type: PolicyItemPrimitives.SimpleTable,
              attribute: `${id}.email-addresses`,
              indent: 2,
              limit: 5,
              errors: PoliciesErrors.errorsInvalidEmail,
              countLabel: 'email-addresses-limit',
              tableColumn: 'email-addresses-label',
              modalDescription: 'email-addresses-description',
              modalTextLabel: 'email-addresses-modal-text-label',
              modalHelperText: 'helper-text',
              duplicateError: 'duplicate-error'
            },
            {
              type: PolicyItemPrimitives.Error,
              error: 'not-selected-error',
              attribute: id,
              indent: 1
            }
          ]
        }

      case PolicyAttributeEnum.Ldap_Setup:
        return {
          id,
          deviceSettings: [
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'check',
              attribute: `${id}.check`,
              update: (value, enableCallback) => {
                const credId = `${id}.credential`
                enableCallback(
                  [
                    `${id}.address`,
                    `${id}.port`,
                    `${id}.ssl`,
                    credId,
                    `${id}.bind-prefix`,
                    `${id}.roots`,
                    `${id}.match-name`,
                    `${id}.retrieve-email`,
                    `${id}.retrieve-name`,
                    `${id}.retrieve-group`,
                    `${id}.exact-match`
                  ],
                  value
                )
                enableCallback([`${credId}_${credId}.admin`], false)
              }
            },
            {
              type: PolicyItemPrimitives.Textbox,
              label: 'address',
              hint: 'address_hint',
              attribute: `${id}.address`,
              errors: PoliciesErrors.errorsDnsDomainName,
              indent: 1
            },
            {
              type: PolicyItemPrimitives.Textbox,
              label: 'port',
              hint: 'port_hint',
              attribute: `${id}.port`,
              errors: PoliciesErrors.errorsPortNumber,
              indent: 1
            },
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'ssl',
              attribute: `${id}.ssl`,
              indent: 1
            },
            {
              type: PolicyItemPrimitives.Dropbox,
              label: 'credential',
              items: DropboxItems.ldapSetupCredentialModes,
              attribute: `${id}.credential`,
              radio: true,
              indent: 1
            },
            {
              type: PolicyItemPrimitives.Textbox,
              label: 'bind-prefix',
              hint: 'bind-prefix_hint',
              attribute: `${id}.bind-prefix`,
              errors: PoliciesErrors.errorsLdapBindPrefix(constraints),
              indent: 2
            },
            {
              label: 'roots',
              type: PolicyItemPrimitives.SimpleTable,
              attribute: `${id}.roots`,
              indent: 1,
              limit: 25,
              emptyAllowed: !constraints?.getSettingMin(
                ConstraintsResourceEnum.ldapConfig,
                ConstraintsSettingEnum.ldapBindRoots
              ),
              errors: PoliciesErrors.errorsNotSelected,
              countLabel: 'roots-limit',
              tableColumn: 'roots-column',
              modalDescription: 'roots-description',
              modalTextLabel: 'roots-modal-text-label',
              modalHelperText: 'helper-text',
              duplicateError: 'duplicate-error'
            },
            {
              type: PolicyItemPrimitives.Textbox,
              label: 'match-name',
              hint: 'name_hint',
              attribute: `${id}.match-name`,
              errors: PoliciesErrors.errorsLdapMatchName(constraints),
              indent: 1
            },
            {
              type: PolicyItemPrimitives.Textbox,
              label: 'retrieve-email',
              hint: 'name_hint',
              attribute: `${id}.retrieve-email`,
              errors: PoliciesErrors.errorsLdapRetrieveEmail(constraints),
              indent: 1
            },
            {
              type: PolicyItemPrimitives.Textbox,
              label: 'retrieve-name',
              hint: 'name_hint',
              attribute: `${id}.retrieve-name`,
              errors: PoliciesErrors.errorsLdapRetrieveName(constraints),
              indent: 1
            },
            {
              type: PolicyItemPrimitives.Textbox,
              label: 'retrieve-group',
              hint: 'name_hint',
              attribute: `${id}.retrieve-group`,
              errors: PoliciesErrors.errorsLdapRetrieveGroup(constraints),
              indent: 1
            },
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'exact-match',
              attribute: `${id}.exact-match`,
              indent: 1
            }
          ]
        }

      case PolicyAttributeEnum.Ca_Certificate:
        return {
          id,
          relatedItems: [PolicyAttributeEnum.Csrf_Prevention],
          deviceSettings: [
            {
              type: PolicyItemPrimitives.FileUpload,
              attribute: `${id}.file`,
              update: PoliciesConfiguration.updateCaCertificate,
              fileUploadExt: '.cer',
              fileUploadType: 'CERTIFICATE'
            }
          ]
        }

      case PolicyAttributeEnum.Email_File:
      case PolicyAttributeEnum.Network_Folder_File:
        return PoliciesConfiguration.aFileSetting(id, constraints)

      case PolicyAttributeEnum.App_Deployment:
        return {
          id,
          deviceSettings: [
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'check',
              attribute: `${id}.check`,
              update: (value, enableCallback) =>
                enableCallback([`${id}.apps`, `${id}.mode`], value)
              /*
              update: (value, enableCallback, setValueCallBack) => {
                const sio = `${id}.sio`
                enableCallback([`${id}.apps`, `${id}.mode`, sio], value)
                if (!value) {
                  setValueCallBack([{ name: sio, value: sFalse }])
                }
              }
               */
            },
            {
              type: PolicyItemPrimitives.Dropbox,
              label: 'mode',
              items: DropboxItems.overwriteModes(id),
              attribute: `${id}.mode`,
              radio: true,
              indent: 1
            },
            {
              type: PolicyItemPrimitives.AppDeployment,
              attribute: `${id}.apps`,
              indent: 1
            }
            /*
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'sio',
              attribute: `${id}.sio`,
              indent: 1,
              update: (value, enableCallback) => {
                enableCallback([`${id}.sio-config`], value)
              }
            },
            {
              type: PolicyItemPrimitives.SioConfig,
              attribute: `${id}.sio-config`,
              indent: 2
            }
             */
          ]
        }

      case PolicyAttributeEnum.Home_Screen_Wallpaper:
        return {
          id,
          deviceSettings: [
            {
              type: PolicyItemPrimitives.Dropbox,
              label: 'mode',
              items: DropboxItems.homeScreenWallpaperModes,
              attribute: `${id}.mode`,
              radio: true,
              update: (value, enableCallback) =>
                enableCallback(
                  [`${id}.files`, `${id}.color`, `${id}.effect`],
                  value === `${id}.mode.custom`
                )
            },
            {
              type: PolicyItemPrimitives.WallpaperUpload,
              attribute: `${id}.files`,
              indent: 1
            },
            {
              type: PolicyItemPrimitives.Dropbox,
              label: 'color',
              items: ColorItems.homeScreenAppFontColor,
              attribute: `${id}.color`,
              radio: true,
              indent: 1
            },
            {
              type: PolicyItemPrimitives.Dropbox,
              label: 'effect',
              items: DropboxItems.homeScreenTextEffects,
              attribute: `${id}.effect`,
              radio: true,
              indent: 1
            }
          ]
        }

      case PolicyAttributeEnum.Home_Screen_App_Fs4:
      case PolicyAttributeEnum.Home_Screen_App_Pro:
        return {
          id,
          deviceSettings: [
            {
              type: PolicyItemPrimitives.Dropbox,
              label: 'mode',
              items: DropboxItems.overwriteModes(id),
              attribute: `${id}.mode`,
              radio: true
            },
            {
              type: PolicyItemPrimitives.HomeScreenApplications,
              attribute: `${id}.apps`,
              simple: id === PolicyAttributeEnum.Home_Screen_App_Pro
            }
          ]
        }

      case PolicyAttributeEnum.CORS:
        return {
          id,
          deviceSettings: [
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'check',
              attribute: `${id}.check`,
              update: (value, enableCallback) =>
                enableCallback([`${id}.sites`], value)
            },
            {
              type: PolicyItemPrimitives.SimpleTable,
              indent: 1,
              label: 'sites',
              attribute: `${id}.sites`,
              countLabel: 'sites-count',
              tableColumn: 'sites',
              modalDescription: 'sites-description',
              modalTextLabel: 'sites-modal-text-label',
              modalHelperText: 'helper-text',
              duplicateError: 'duplicate-error',
              errors: PoliciesErrors.errorsCorsSiteNameName
            }
          ]
        }

      case PolicyAttributeEnum.WiFi_Direct:
        return {
          id,
          deviceSettings: [
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'check',
              attribute: `${id}.check`,
              update: (value, enableCallback) => {
                const methodId = `${id}.method`
                enableCallback(
                  [`${id}.suffix`, `${id}.channel`, `${id}.method`],
                  value
                )
                enableCallback(
                  [
                    `${methodId}_${methodId}.manual`,
                    `${methodId}_${methodId}.advanced`
                  ],
                  false
                )
              }
            },
            {
              type: PolicyItemPrimitives.Textbox,
              label: 'suffix',
              hint: 'suffix_hint',
              attribute: `${id}.suffix`,
              indent: 1
            },
            {
              type: PolicyItemPrimitives.Dropbox,
              label: 'channel',
              items: WiFiDirectItems.wifiDirectChannels,
              attribute: `${id}.channel`,
              indent: 1
            },
            {
              type: PolicyItemPrimitives.Dropbox,
              label: 'method',
              items: WiFiDirectItems.wifiDirectConnectionMethods,
              attribute: `${id}.method`,
              radio: true,
              indent: 1
            }
          ]
        }

      case PolicyAttributeEnum.Sleep_Schedule:
        return {
          id,
          deviceSettings: [
            {
              type: PolicyItemPrimitives.WeeklySchedule,
              attribute: `${id}.weekly`
            },
            {
              type: PolicyItemPrimitives.HolidaySchedule,
              attribute: `${id}.holiday`
            }
          ]
        }

      case PolicyAttributeEnum.IP_Fax:
        return {
          id,
          deviceSettings: [
            {
              type: PolicyItemPrimitives.Textbox,
              label: 'company-name',
              hint: 'company-name_hint',
              errors: PoliciesErrors.errorsNotSelected,
              attribute: `${id}.company-name`
            },
            {
              type: PolicyItemPrimitives.Textbox,
              label: 'fax-id',
              hint: 'fax-id_hint',
              errors: PoliciesErrors.errorsNotSelected,
              attribute: `${id}.fax-id`
            }
          ]
        }

      case PolicyAttributeEnum.Pin_Protection:
        return {
          id,
          deviceSettings: [
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'four-digits',
              attribute: `${id}.four-digits`
            },
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'scan-job',
              attribute: `${id}.scan-job`
            },
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'print-job',
              attribute: `${id}.print-job`
            },
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'allow-cancel',
              attribute: `${id}.allow-cancel`
            }
          ]
        }
      case PolicyAttributeEnum.Consumable_Access_Ctrl:
        return {
          id,
          deviceSettings: [
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'check',
              help: 'check_help',
              attribute: `${id}.check`,
              update: (value, enableCallback) =>
                enableCallback([`${id}.threshold`], value)
            },
            {
              type: PolicyItemPrimitives.Dropbox,
              label: 'threshold',
              items: DropboxItems.consumableAccessCtrlThresholds,
              attribute: `${id}.threshold`,
              radio: true,
              indent: 1
            }
          ]
        }
      case PolicyAttributeEnum.Device_Access_Ctrl:
        return {
          id,
          deviceSettings: [
            {
              type: PolicyItemPrimitives.Dropbox,
              label: 'mode',
              items: DropboxItems.overwriteModes(id),
              attribute: `${id}.mode`,
              radio: true,
              update: (value, enableCallback, setCallback) =>
                PoliciesConfiguration.resetChecks(
                  value === `${id}.mode.append`,
                  enableCallback,
                  setCallback,
                  [`${id}.overwrite-existing`]
                )
            },
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'overwrite-existing',
              attribute: `${id}.overwrite-existing`,
              indent: 1
            },
            {
              type: PolicyItemPrimitives.AccessCtrlRoles,
              attribute: `${id}.roles`
            },
            {
              type: PolicyItemPrimitives.Dropbox,
              label: 'ctrl-panel-sign-in',
              items: DeviceAccessItems.signInMethods.slice(1),
              attribute: `${id}.ctrl-panel-sign-in`
            },
            {
              type: PolicyItemPrimitives.AccessCtrlPermissions,
              label: 'ctrl-panel-permissions',
              items: DeviceAccessItems.signInMethods,
              attribute: `${id}.ctrl-panel-permissions`
            },
            {
              type: PolicyItemPrimitives.Dropbox,
              label: 'ews-sign-in',
              items: DeviceAccessItems.signInEwsMethods.slice(1),
              attribute: `${id}.ews-sign-in`
            },
            {
              type: PolicyItemPrimitives.AccessCtrlPermissions,
              label: 'ews-permissions',
              items: DeviceAccessItems.signInEwsMethods,
              attribute: `${id}.ews-permissions`
            },
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'allow-alt-sign-in',
              help: 'allow-alt-sign-in_help',
              attribute: `${id}.allow-alt-sign-in`
            },
            {
              type: PolicyItemPrimitives.Toggle,
              label: 'auto-sign-out',
              help: 'auto-sign-out_help',
              attribute: `${id}.auto-sign-out`,
              update: (value, enableCallback) =>
                enableCallback([`${id}.sign-out-after`], value)
            },
            {
              type: PolicyItemPrimitives.Dropbox,
              items: DeviceAccessItems.signOutAfterOptions,
              attribute: `${id}.sign-out-after`,
              radio: true,
              indent: 1
            }
          ]
        }
    }
  }

  static getPolicyAttributeDescription(
    id: PolicyAttributeEnum,
    constraints?: PolicyConstraints,
    excludeSettings?: string[]
  ): PolicyAttributeDescriptionEx {
    const description = {
      id, // default for unknown attributes
      ...PoliciesConfiguration.getConfiguration(id, constraints),
      categories: PoliciesCategories.getPolicyAttributeCategory(id)
    }
    if (!description.deviceSettings) {
      description.deviceSettings = [] // default for unknown attributes
    } else if (excludeSettings) {
      // Exclude some device settings for global policy
      description.deviceSettings = description.deviceSettings.filter(
        ({ attribute }) => !excludeSettings.includes(attribute)
      )
    }
    if (constraints) {
      // Exclude some device settings for device-specific policy
      description.deviceSettings = description.deviceSettings.filter(
        ({ attribute }) =>
          constraints.isPolicyAttributeSettingSupported(id, attribute)
      )
    }
    return description
  }

  static getPolicyAttrLocPath(id: PolicyAttributeEnum | string): string {
    return 'policy.settings.attributes.' + id
  }

  static sortPolicyAttributes(
    attributes: { name: PolicyAttributeEnum | string }[],
    translate: (string) => string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): any[] {
    const s = 'sortable'
    return abcSort(
      attributes.map((x) => {
        x[s] = translate(
          PoliciesConfiguration.getPolicyAttrLocPath(x.name) + '.name'
        )
        return x
      }),
      (x) => x[s]
    ).map((x) => {
      delete x[s]
      return x
    })
  }
}
