import React, { useContext, useEffect, useMemo, useState } from 'react'
import {
  Button,
  ButtonGroup,
  IconCheckmark,
  Modal,
  Stepper,
  StepsType,
  IconChevronRight,
  IconChevronLeft,
  Card
} from '@veneer/core'
import 'styles/global.scss'
import { FlexRowWithSpace } from 'styles/styles'
import configContext from 'context/config/configContext'
import CreatePolicyStep2 from './CreatePolicySteps/CreatePolicyStep2'
import CreatePolicyStep3 from './CreatePolicySteps/CreatePolicyStep3'
import solutionsContext from 'context/solutions/solutionsContext'
import policiesContext from 'context/policies/policiesContext'
import { newPolicy, newDevicePolicy } from 'common/setPolicy'
import ConfirmSave from '../modal/confirmSave'
import CreatePolicySuccessModal from '../modal/CreatePolicySuccess'
import { getAttributesWithEntitlement } from 'common/utilities'
import {
  allSolutionId,
  PolicyAttributeEntitlementEnum,
  ToastIdEnum
} from 'components/policies/constants'
import LabelTextBox from 'common/controls/labelTextBox'
import LabelSelect from 'common/controls/labelSelect'
import HelpButton from 'components/policies/settings/attributes/HelpButton'
import configData from 'common/config/settings.json'

export enum StepperStatus {
  Current = 'current',
  Complete = 'complete',
  Incomplete = 'incomplete'
}

const CreatePolicy = (props) => {
  const { t } = useContext(configContext)
  const { hasEntitlement } = useContext(solutionsContext)
  const {
    templates,
    getAllPolicies,
    createPolicy,
    saveDevicePolicy,
    removeDevicePolicy,
    getPolicyTemplates
  } = useContext(policiesContext)
  const {
    deviceId,
    constraints,
    entitlements,
    attributes,
    displayToast,
    onClose
  } = props

  const key = (subkey: string, args?: Record<string, string>): string => {
    return t(`policy.create.${subkey}`, args)
  }

  const createPolicySteps = (): StepsType[] =>
    deviceId
      ? [
          {
            label: key('step2.stepLabel'),
            status: StepperStatus.Current,
            step: '1'
          },
          {
            label: key('step3.stepLabel'),
            status: StepperStatus.Incomplete,
            step: '2'
          }
        ]
      : [
          {
            label: key('step1.stepLabel'),
            status: StepperStatus.Current,
            step: '1'
          },
          {
            label: key('step2.stepLabel'),
            status: StepperStatus.Incomplete,
            step: '2'
          },
          {
            label: key('step3.stepLabel'),
            status: StepperStatus.Incomplete,
            step: '3'
          }
        ]

  const [isWarning, setIsWarning] = useState(false)
  const [showWarningModal, setShowWarningModal] = useState(false)
  const [isError, setIsError] = useState(false)
  const [confirmationModal, setConfirmationModal] = useState({ show: false })
  const [selectedPolicyAttributes, setSelectedPolicyAttributes] = useState([])

  const [policyName, setPolicyName] = useState('')
  const [policyDescription, setPolicyDescription] = useState('')
  const [stepItems, setStepItems] = useState(createPolicySteps())
  const [currentIndex, setCurrentIndex] = useState(0)

  const [hasAdvanced, setHasAdvanced] = useState(false)
  const [entitlement, setEntitlement] = useState([])

  const [displayPolicyNameError, setDisplayPolicyNameError] = useState(false)
  const [displayEntitlementError, setDisplayEntitlementError] = useState(false)
  const [displayAllPolicyErrors, setDisplayAllPolicyErrors] = useState(false)

  const stepBasic = !deviceId && !currentIndex
  const stepSelect = currentIndex === stepItems.length - 2

  useEffect(() => {
    if (attributes) {
      setSelectedPolicyAttributes(attributes)
    }
  }, [attributes])

  useEffect(() => {
    hasEntitlement(PolicyAttributeEntitlementEnum.ADVANCED, setHasAdvanced)
    getPolicyTemplates((error) =>
      displayToast(ToastIdEnum.GET_TEMPLATES, error, 'negative')
    )
  }, [])

  const policyAttributes = useMemo(() => {
    if (templates) {
      let attrs = getAttributesWithEntitlement(
        hasAdvanced
          ? templates
          : templates.filter(
              (x) => x.entitlement !== PolicyAttributeEntitlementEnum.ADVANCED
            )
      )
      if (deviceId) {
        attrs = attrs.concat(configData.settings.device.specific.attributes)
      }
      return constraints
        ? attrs
            .filter((x) => constraints.isPolicyAttributeSupported(x.name))
            .map((x) => {
              const deviceSettings = []
              x.deviceSettings.forEach((y) => {
                if (
                  constraints.isPolicyAttributeSettingSupported(x.name, y.name)
                ) {
                  deviceSettings.push(y)
                }
              })
              x.deviceSettings = deviceSettings
              return x
            })
        : attrs
    }
    return []
  }, [templates, hasAdvanced, constraints])

  const templateItems = useMemo(() => {
    const getTemplateName = (x) => {
      switch (x.entitlement) {
        case PolicyAttributeEntitlementEnum.ESSENTIAL:
          return key('step1.templates.essential')
        case PolicyAttributeEntitlementEnum.ADVANCED:
          return key('step1.templates.advanced')
        default:
          return x.name
      }
    }
    const items = templates
      ? templates
          .filter(
            (x) =>
              x.entitlement &&
              (hasAdvanced ||
                x.entitlement !== PolicyAttributeEntitlementEnum.ADVANCED)
          )
          .sort((a, b) => (a.entitlement > b.entitlement ? 1 : -1)) // smcloud, smcloud-advanced
          .map((y) => {
            return {
              value: y.entitlement,
              label: getTemplateName(y)
            }
          })
      : []
    items.push({
      value: allSolutionId,
      label: key('step1.dropdownDefItem')
    })
    return items
  }, [templates, hasAdvanced])

  const handleNextButtonOnChange = () => {
    if (stepBasic) {
      setDisplayPolicyNameError(true)
      setDisplayEntitlementError(true)
      if (policyName === '' || !entitlement.length) {
        return
      }
    }

    const newStepItems = [...stepItems]
    const oldSelectedItem = newStepItems[currentIndex]
    newStepItems[currentIndex + 1].status = StepperStatus.Current
    oldSelectedItem.status = StepperStatus.Complete
    oldSelectedItem.step = <IconCheckmark filled />
    setStepItems(newStepItems)
    setCurrentIndex(currentIndex + 1)
  }

  const handleBackButtonOnChange = () => {
    if (stepSelect) {
      const checkEntitlement = (ent) =>
        selectedPolicyAttributes.every((x) =>
          x.metadata.entitlements.includes(ent)
        ) &&
        selectedPolicyAttributes.length ===
          policyAttributes.filter((x) => x.metadata.entitlements.includes(ent))
            .length
      setEntitlement([
        (checkEntitlement(PolicyAttributeEntitlementEnum.ESSENTIAL) &&
          PolicyAttributeEntitlementEnum.ESSENTIAL) ||
          (checkEntitlement(PolicyAttributeEntitlementEnum.ADVANCED) &&
            PolicyAttributeEntitlementEnum.ADVANCED) ||
          allSolutionId
      ])
    }

    const newStepItems = [...stepItems]
    newStepItems[currentIndex - 1].status = StepperStatus.Current
    newStepItems[currentIndex].status = StepperStatus.Incomplete
    setStepItems(newStepItems)
    setCurrentIndex(currentIndex - 1)
  }

  const handleCreate = () => {
    setShowWarningModal(isWarning)
    if (!isWarning) {
      handleCreateRequest()
    }
  }

  const handleRemove = () => {
    removeDevicePolicy(deviceId, () => {
      displayToast(ToastIdEnum.REMOVE_DEVICE_POLICY, key('policyRemoved'))
      onClose()
    })
  }

  const handleCreateRequest = () => {
    const attrs = selectedPolicyAttributes.map((x) => {
      return {
        name: x.name,
        description: x.description,
        metadata: x.metadata,
        deviceSettings: x.deviceSettings
      }
    })
    if (deviceId) {
      saveDevicePolicy(newDevicePolicy(deviceId, attrs), () => {
        attributes.length
          ? displayToast(ToastIdEnum.SAVE_DEVICE_POLICY, key('policySaved'))
          : displayToast(ToastIdEnum.CREATE_DEVICE_POLICY, key('policyCreated'))
        onClose()
      })
    } else {
      createPolicy(
        newPolicy(policyName.trim(), policyDescription.trim(), attrs),
        () => {
          setConfirmationModal({ show: true })
          getAllPolicies((error) =>
            displayToast(ToastIdEnum.CREATE_POLICY, error, 'negative')
          )
        }
      )
    }
  }

  const inputForm = () => {
    const error = displayPolicyNameError && !policyName
    const errorEntitlement = displayEntitlementError && !entitlement.length
    return (
      <>
        <div className={'create-modal-form-1'}>
          <LabelTextBox
            required={true}
            className={'marginBottom16'}
            label={key('step1.textboxLabel')}
            id={'textboxLabel'}
            value={policyName}
            onChange={(name) => {
              setPolicyName(name)
              setDisplayPolicyNameError(true)
            }}
            placeholder={key('step1.textboxPlaceholder')}
            error={error}
            helperText={key(
              error ? 'step1.textboxError' : 'step1.textboxPlaceholder'
            )}
          />
          <LabelSelect
            required={true}
            className={'marginBottom16'}
            label={key('step1.dropdownLabel')}
            helpButton={
              <HelpButton
                enabled={true}
                description={key('step1.dropdownHint')}
              />
            }
            id={'dropdownLabel'}
            placeholder={key('step1.dropdownPlaceholder')}
            helperText={errorEntitlement ? key('step1.dropdownError') : null}
            options={templateItems}
            value={entitlement}
            error={errorEntitlement}
            onChange={({ value: v }) => {
              setEntitlement([v])
              setDisplayEntitlementError(true)
              // Pick policy attributes according to selected type
              setSelectedPolicyAttributes(
                v === allSolutionId
                  ? []
                  : policyAttributes.filter((x) =>
                      x.metadata.entitlements.includes(v)
                    )
              )
            }}
          />
          <LabelTextBox
            label={key('step1.textAreaLabel')}
            id={'textAreaLabel'}
            value={policyDescription}
            onChange={(value) => setPolicyDescription(value)}
            placeholder={key('step1.textAreaPlaceholder')}
            className={'textAreaHeight'}
            textArea={true}
          />
        </div>
      </>
    )
  }

  const params = {
    selectedCount: selectedPolicyAttributes?.length,
    totalAttributes: policyAttributes?.length
  }

  const saveBtn = () => {
    const btn = (title, onClick, disabled = false) => (
      <Button
        appearance={'primary'}
        onClick={() => {
          setDisplayAllPolicyErrors(true)
          if (!isError) {
            onClick()
          }
        }}
        disabled={disabled}
        id={'createBtn'}
      >
        {title}
      </Button>
    )
    const noSelected = !selectedPolicyAttributes.length
    return !deviceId
      ? btn(t('common.create'), handleCreate, noSelected)
      : !attributes.length
      ? btn(key('create'), handleCreate, noSelected)
      : noSelected
      ? btn(key('remove'), handleRemove)
      : btn(t('common.save'), handleCreate)
  }

  return (
    <>
      <Modal
        className={'full-window-modal'}
        closeOnBlur={false}
        show={true}
        footer={
          <FlexRowWithSpace className={'footerBorder fullWidth'}>
            <div>
              <Button appearance={'secondary'} onClick={onClose}>
                {t('common.cancel')}
              </Button>
              {stepSelect && (
                <span className={'countPadding'}>
                  {t('policy.create.count', params)}
                </span>
              )}
            </div>
            <ButtonGroup>
              {currentIndex > 0 && (
                <Button
                  appearance={'secondary'}
                  onClick={handleBackButtonOnChange}
                  leadingIcon={<IconChevronLeft />}
                  id={'backBtn'}
                >
                  {t('common.back')}
                </Button>
              )}
              {currentIndex < stepItems.length - 1 ? (
                <Button
                  appearance={'primary'}
                  onClick={handleNextButtonOnChange}
                  disabled={
                    stepSelect && !selectedPolicyAttributes.length && !deviceId
                  }
                  trailingIcon={<IconChevronRight />}
                  id={'nextBtn'}
                >
                  {t('common.next')}
                </Button>
              ) : (
                saveBtn()
              )}
            </ButtonGroup>
          </FlexRowWithSpace>
        }
      >
        <FlexRowWithSpace className={'modalHeader'}>
          <h5 className={'createPolicyTitle'}>
            {key(deviceId ? 'deviceTitle' : 'title')}
          </h5>
          <Stepper
            appearance={'standard'}
            steps={stepItems}
            interactive
            customStyle={{
              padding: '0px',
              height: '64px',
              '> div .label': { width: '150px' }
            }}
          />
        </FlexRowWithSpace>
        <div className={'create-modal-body'}>
          {stepBasic ? (
            <>
              <h5 className={'step-heading'}>{key('step1.title')} </h5>
              <p className={'contentPadding'}>{key('step1.content')}</p>
              <Card content={inputForm()} border={'dropShadow'} />
            </>
          ) : stepSelect ? (
            <>
              <h5 className={'step-heading'}>
                {key('step2.title', { step: `${currentIndex + 1}` })}
              </h5>
              <p className={'contentPadding'}>{key('step2.content')}</p>
              <CreatePolicyStep2
                setSelectedPolicyAttributes={setSelectedPolicyAttributes}
                selectedPolicyAttributes={selectedPolicyAttributes}
                policyAttributes={policyAttributes}
              />
            </>
          ) : (
            <>
              <h5 className={'step-heading'}>
                {key('step3.title', { step: `${currentIndex + 1}` })}
              </h5>
              <p className={'contentPadding'}>{key('step3.content')}</p>
              <CreatePolicyStep3
                selectedPolicyAttributes={selectedPolicyAttributes}
                setSelectedPolicyAttributes={setSelectedPolicyAttributes}
                handleError={setIsError}
                handleWarning={setIsWarning}
                entitlements={entitlements}
                policyConstraints={constraints}
                devicePolicy={!!deviceId}
                showErrors={displayAllPolicyErrors}
              />
            </>
          )}
        </div>
      </Modal>
      <CreatePolicySuccessModal
        show={confirmationModal.show}
        policyName={policyName}
        onDone={() => {
          setConfirmationModal({ show: false })
          onClose()
        }}
      />
      {isWarning && (
        <ConfirmSave
          show={showWarningModal}
          onCancel={() => setShowWarningModal(false)}
          onConfirm={() => {
            setShowWarningModal(false)
            handleCreateRequest()
          }}
        />
      )}
    </>
  )
}

export default CreatePolicy
