import React, { useContext, useEffect, useState, memo, useMemo } from 'react'
import settingsContext from 'context/settings/settingsContext'
import configContext from 'context/config/configContext'
import { Table, Button } from '@veneer/core'
import PreviewItem from 'components/policies/settings/attributes/device/previewItem'
import { ColumnIndexTypes } from '@veneer/core/dist/scripts/table'
import {
  ObjectAttributeDescription,
  Item
} from 'context/policies/PoliciesConfiguration'
import PoliciesHelper from 'context/policies/PoliciesHelper'
import FaxMethodItems from 'context/policies/dropboxItems/FaxMethodItems'
import PrintEmailNotificationItems from 'context/policies/dropboxItems/PrintEmailNotificationItems'
import ImageSettingItems from 'context/policies/dropboxItems/ImageSettingItems'
import FaxSendCommonModal, { faxSendCommonEnum } from './FaxSendCommonModal'
import FaxSendModemModal, { faxSendModemEnum } from './FaxSendModemModal'
import LabelRadioButtons from 'common/controls/labelRadioButtons'
import HelpButton from '../../../HelpButton'
import FaxSendTextModal from './FaxSendTextModal'
import WarningMessage from '../WarningMessage'
import { FlexColumn, PreviewLabel } from 'styles/styles'
import 'styles/global.scss'
import PoliciesErrors, {
  MessageTypesEnum
} from 'context/policies/PoliciesErrors'
import Retrievei18nItems from 'common/utilityItems/Retrievei18nItems'
import { PolicyAttributeEnum } from 'context/policies/PoliciesCategories'

const columnId = 'id'
const columnOption = 'option'
const columnValue = 'value'
const tablePref = {
  width: [
    { columnId: columnOption, width: 240 },
    { columnId: columnValue, width: 360 }
  ]
}

const filler = (id) => {
  return { id, title: `${id}-title`, button: `${id}-settings` }
}
const tables = [filler('common'), filler('modem'), filler('internet')] // FaxSendItems

const boolItems: Item[] = [
  { value: 'true', label: 'common.on' },
  { value: 'false', label: 'common.off' }
]

export enum faxSendInternetEnum {
  DOMAIN = 'internet-domain',
  EMAIL = 'internet-email'
}

enum faxSendEnum {
  METHOD = 'method',
  SETTINGS = 'settings'
}

const FaxSendControl = (props) => {
  const description: ObjectAttributeDescription = props.description
  const {
    attributes,
    localizationPath,
    data: { deviceSettings },
    onAttributeChange
  } = props
  const { attribute } = description

  const { tt } = useContext(configContext)
  const {
    isEnabled,
    addDisabled,
    removeDisabled,
    addError,
    removeError,
    displayAllErrors
  } = useContext(settingsContext)

  const getLocalized = (key: string, params?): string =>
    tt(localizationPath, key, params)

  const initArray = (x) => Array.from(Array(tables.length)).map(() => x)
  const [showModal, setShowModal] = useState(initArray(false))
  const [error, setError] = useState(initArray(false))
  const [displayError, setDisplayError] = useState(initArray(displayAllErrors))

  useEffect(
    () => setDisplayError((x) => x.map((y) => y || displayAllErrors)),
    [displayAllErrors]
  )

  const value = useMemo(
    () => PoliciesHelper.getData(description, deviceSettings),
    [deviceSettings]
  )

  const compliance = useMemo(
    () => PoliciesHelper.getCompliance(description, deviceSettings),
    [deviceSettings]
  )

  const onChange = (v, i?) => {
    if (i !== undefined) {
      setDisplayError((x) => {
        x[i] = true
        return x
      })
    }
    const newValue = { ...value, ...v }
    const settings = [...deviceSettings]
    PoliciesHelper.setData(description, settings, newValue)
    onAttributeChange({ ...props.data, deviceSettings: settings })
  }

  useEffect(() => {
    if (attributes) {
      PoliciesHelper.update(
        description,
        value,
        (ids, value) => (value ? removeDisabled(ids) : addDisabled(ids)),
        props.onSettingsChanges,
        attributes
      )
    }
  }, [value, attributes])

  const checkConstraints = (x) =>
    description.constraints
      ? !!description.constraints.isPolicyAttributeSettingSupported(
          PolicyAttributeEnum.Fax_Send,
          `${PolicyAttributeEnum.Fax_Send}.${faxSendEnum.SETTINGS}.${x}`
        )
      : true

  const getTableData = (val) => {
    const item = (id, items?) => {
      const found = items?.find((v) => v.value === val[id])
      const text = found ? getLocalized(found.label) : val[id]
      const status = compliance[id]
      return {
        id,
        option: getLocalized(id),
        value: (
          <>
            {text}
            {status && <PreviewItem compliance={status} />}
          </>
        )
      }
    }
    return [
      [
        item(
          faxSendCommonEnum.BG_CLEANUP,
          ImageSettingItems.imageBgCleanupItems
        ),
        item(faxSendCommonEnum.DARKNESS, ImageSettingItems.imageDarknessItems),
        item(faxSendCommonEnum.CONTRAST, ImageSettingItems.imageContrastItems),
        item(
          faxSendCommonEnum.SHARPNESS,
          ImageSettingItems.imageSharpnessItems
        ),
        item(
          faxSendCommonEnum.NOTIFICATION_CONDITION,
          PrintEmailNotificationItems.emailNotificationConditions
        ),
        item(
          faxSendCommonEnum.NOTIFICATION_METHOD,
          PrintEmailNotificationItems.emailNotificationMethods
        ),
        item(faxSendCommonEnum.NOTIFICATION_ADDRESS)
      ],
      [faxSendModemEnum.ECM, faxSendModemEnum.JBIG_COMPRESSION]
        .filter((x) => checkConstraints(x))
        .map((x) => item(x, boolItems)),
      [item(faxSendInternetEnum.DOMAIN), item(faxSendInternetEnum.EMAIL)]
    ]
  }

  const tableData = useMemo(() => getTableData(value), [value])

  const tableColumns = useMemo(() => {
    const index: ColumnIndexTypes = 'hidden'
    return [
      { id: columnId, label: columnId, index },
      { id: columnOption, label: getLocalized('option-name') },
      { id: columnValue, label: getLocalized('value') }
    ]
  }, [])

  const enabled = isEnabled(attribute)
  const i18nItems = Retrievei18nItems()
  const table = (i, cn?) => (
    <FlexColumn key={tables[i].button} className={cn}>
      {onAttributeChange ? (
        <div className={'marginBottom12'}>
          <Button
            appearance={'secondary'}
            disabled={!enabled}
            onClick={() => setShowModal(showModal.map((_, j) => j === i))}
          >
            {getLocalized(tables[i].button)}
          </Button>
        </div>
      ) : (
        <PreviewLabel>{getLocalized(tables[i].title)}</PreviewLabel>
      )}
      <Table
        columns={tableColumns}
        data={tableData[i]}
        className={'widthColAuto'}
        preferences={tablePref}
        i18n={i18nItems}
      />
      {error[i] && (
        <WarningMessage
          id={tables[i].title + '.error'}
          type={MessageTypesEnum.ERROR}
          message={getLocalized('common.errors.not-selected')}
        />
      )}
    </FlexColumn>
  )

  const index = useMemo(() => {
    const idx = FaxMethodItems.faxSendMethods().findIndex(
      (x) => x.value === value.method
    )
    return idx < 0 ? 0 : idx // Internal Modem is default
  }, [value])

  useEffect(() => {
    const ids = [
      [
        faxSendCommonEnum.BG_CLEANUP,
        faxSendCommonEnum.DARKNESS,
        faxSendCommonEnum.CONTRAST,
        faxSendCommonEnum.SHARPNESS,
        faxSendCommonEnum.NOTIFICATION_CONDITION
      ],
      [faxSendModemEnum.ECM, faxSendModemEnum.JBIG_COMPRESSION].filter((x) =>
        checkConstraints(x)
      ),
      [faxSendInternetEnum.DOMAIN, faxSendInternetEnum.EMAIL]
    ]
    const err = Array.from(Array(tables.length)).map(
      enabled
        ? (_, i) => (!i || i === index + 1) && ids[i].some((y) => !value[y])
        : () => false
    )
    if (!err[0]) {
      // check dependant fields
      err[0] =
        value[faxSendCommonEnum.NOTIFICATION_CONDITION] !== //notify
          PrintEmailNotificationItems.emailNotificationConditions[0].value &&
        value[faxSendCommonEnum.NOTIFICATION_METHOD] === // by email
          PrintEmailNotificationItems.emailNotificationMethods[1].value &&
        !value[faxSendCommonEnum.NOTIFICATION_ADDRESS]
    }
    setError(err.map((x, i) => x && displayError[i]))
    err.forEach((x, i) =>
      x
        ? addError(props.id, attribute + tables[i].button, displayError[i])
        : removeError(props.id, attribute + tables[i].button)
    )
  }, [enabled, value, displayError])

  const radio = (label, methods) => {
    return onAttributeChange ? (
      <LabelRadioButtons
        disabled={!enabled}
        label={getLocalized(label)}
        className={'marginBottom16'}
        id={'id-fax-send-method'}
        value={value.method}
        helpButton={
          <HelpButton
            enabled={enabled}
            description={getLocalized(label + '_help')}
          />
        }
        onChange={(_, method) => onChange({ method })}
        options={methods.map((item) => {
          return { value: item.value, label: getLocalized(item.label) }
        })}
      />
    ) : (
      <PreviewItem
        label={`${localizationPath}.${label}`}
        value={getLocalized(FaxMethodItems.faxSendMethods()[index].label)}
        compliance={compliance.method}
        className={'marginBottom16'}
      />
    )
  }

  const modalParams = (index) => {
    return {
      value,
      getLocalized,
      onChange: (v) => onChange(v, index),
      onClose: () => setShowModal(showModal.map(() => false)),
      id: tables[index].id,
      key: tables[index].id
    }
  }

  const pair = (key, errors) => {
    return { key, errors }
  }

  const modals = [
    (modalProps) => <FaxSendCommonModal {...modalProps} />,
    (modalProps) => (
      <FaxSendModemModal {...modalProps} checkConstraints={checkConstraints} />
    ),
    (modalProps) => (
      <FaxSendTextModal
        {...modalProps}
        textBoxParams={[
          pair(faxSendInternetEnum.DOMAIN, PoliciesErrors.errorsFaxDomainName),
          pair(faxSendInternetEnum.EMAIL, PoliciesErrors.errorsInvalidEmail)
        ]}
      />
    )
  ]

  return (
    <>
      <FlexColumn>
        {checkConstraints(faxSendEnum.METHOD) &&
          radio(
            faxSendEnum.METHOD,
            FaxMethodItems.faxSendMethods(description.constraints)
          )}
        {table(0)}
        {index < tables.length - 1 && table(index + 1, 'paddingTop16')}
      </FlexColumn>
      {showModal.map((x, i) => x && modals[i](modalParams(i)))}
    </>
  )
}

export default memo(FaxSendControl)
