import { CollectionsActions } from 'context/types'

export default (state, action) => {
  switch (action.type) {
    case CollectionsActions.GET_DEVICES:
      return {
        ...state,
        devices: action.payload
      }
    case CollectionsActions.GET_RESOURCES:
      return {
        ...state,
        resources: action.payload
      }
    default:
      return state
  }
}
