import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Button, IconHelp, Search, Table, Scrollbar } from '@veneer/core'
import configContext from 'context/config/configContext'
import PoliciesConfiguration from 'context/policies/PoliciesConfiguration'
import PoliciesCategories from 'context/policies/PoliciesCategories'
import { isAdvanced, getRowSelectAllState, abcSort } from 'common/utilities'
import Help from 'components/policies/modal/help/index'
import uuid from 'react-uuid'
import {
  BadgeLabel,
  FlexColumn,
  FlexColumnTree,
  FlexColumnRight,
  FlexRow
} from 'styles/styles'
import RelatedItemsCard from './Step2RelatedItemsCard'
import { getDecoratedName } from 'common/decoratedName'
import { TableSortBy, SortTypes } from '@veneer/core/dist/scripts/table'
import PolicyCategories from 'components/policies/categories'
import Retrievei18nItems from 'common/utilityItems/Retrievei18nItems'

const nameColumnId = 'name'
const pathColumnId = 'path'
const defSortOrder: SortTypes = 'descending'
const defSorting: TableSortBy = {
  id: nameColumnId,
  type: defSortOrder
}

const CreatePolicyStep2 = (props) => {
  const {
    policyAttributes,
    selectedPolicyAttributes,
    setSelectedPolicyAttributes
  } = props

  const { t, isWex, getCategoryName } = useContext(configContext)

  const key = (subkey: string): string => {
    return t(`policy.create.step2.${subkey}`)
  }

  const [helpText, setHelpText] = useState(null)
  const [data, setData] = useState(null)
  const [search, setSearch] = useState('')
  const [sorting, setSorting] = useState(defSorting)
  const [rowSelectAllState, setRowSelectAllState] = useState(undefined)

  const [selectedCategory, setSelectedCategory] = useState([])

  const getAttribute = (id) => policyAttributes.find((x) => x.name === id)

  const setTableData = (attributes) => {
    const getPath = (name) =>
      getCategoryName(PoliciesCategories.getPolicyAttributeCategory(name))
    setData(
      attributes?.map((item, index) => {
        const path = PoliciesConfiguration.getPolicyAttrLocPath(item.name)
        return {
          name: t(`${path}.name`),
          advanced: isAdvanced(item.metadata.entitlements),
          uid: index,
          id: item.name,
          path: getPath(item.name),
          rowConfig: {
            selected: !!selectedPolicyAttributes.find(
              (x) => x.name === item.name
            )
          }
        }
      })
    )
  }

  useEffect(() => {
    setTableData(
      PoliciesCategories.filterPolicyAttributes(
        selectedCategory,
        policyAttributes
      )
    )
  }, [policyAttributes, selectedPolicyAttributes, selectedCategory])

  const itemName = (id, attributeName, searchVal, advanced) => {
    const decoratedName = getDecoratedName(attributeName, searchVal, isWex)
    return (
      <FlexRow className={'alignCenter'}>
        <p>{decoratedName.length ? decoratedName : attributeName}</p>
        <FlexRow className={'paddingLeft6'}>
          <Button
            id={'policySettingDescription'}
            key={uuid()}
            className={'iconButton alignSelfCenter'}
            appearance={'ghost'}
            small={true}
            leadingIcon={<IconHelp />}
            onClick={() => {
              const path = PoliciesConfiguration.getPolicyAttrLocPath(id)
              setHelpText({
                name: attributeName,
                description: t(`${path}.description`)
              })
            }}
          />
        </FlexRow>
        {advanced && (
          <BadgeLabel className={'marginLeft8'}>
            {t('policy.settings.entitlements.advanced')}
          </BadgeLabel>
        )}
      </FlexRow>
    )
  }

  const itemPath = (path, searchVal) => {
    const decoratedPath = getDecoratedName(path, searchVal, isWex)
    return <p>{decoratedPath.length ? decoratedPath : path}</p>
  }

  const filteredData = useMemo(() => {
    const searchLowerCase = search.toLowerCase()
    return data && data.length && search.length
      ? data.filter(
          (item) =>
            item.name.toLowerCase().includes(searchLowerCase) ||
            item.path.toLowerCase().includes(searchLowerCase)
        )
      : data
  }, [search, data])

  const tableData = useMemo(() => {
    let sortedData = filteredData
    if (data && data.length && sorting.type) {
      sortedData = abcSort(
        [...sortedData],
        sorting.id === pathColumnId ? (x) => x.path : (x) => x.name,
        sorting.type
      )
    }
    const searchLowerCase = search.toLowerCase()
    return sortedData?.map((attr) => {
      return {
        name: itemName(attr.id, attr.name, searchLowerCase, attr.advanced),
        uid: attr.uid,
        id: attr.id,
        path: itemPath(attr.path, searchLowerCase),
        rowConfig: attr.rowConfig
      }
    })
  }, [filteredData, sorting])

  const selectRelatedItem = (relatedItemId) => {
    const found = data.find((row) => row.id === relatedItemId)
    if (found) {
      found.rowConfig.selected = true
    }
    setSelectedPolicyAttributes([
      ...selectedPolicyAttributes,
      getAttribute(relatedItemId)
    ])
    setHelpText(null)
  }

  const handleRowSelect = (event, rowId) => {
    const { checked } = event.target
    const found = data.find((row) => row.uid === rowId)
    if (found) {
      found.rowConfig.selected = checked
      setSelectedPolicyAttributes(
        checked
          ? [...selectedPolicyAttributes, getAttribute(found.id)]
          : selectedPolicyAttributes.filter((x) => x.name !== found.id)
      )
    }
  }

  const handleSelectAllPageItems = (event) => {
    let { checked } = event.target
    if (checked && !tableData?.find(({ rowConfig }) => !rowConfig.selected)) {
      checked = false // there is nothing to select more, so unselect instead
    }
    const ids = tableData.map((row) => {
      row.rowConfig.selected = checked
      return row.id
    })
    if (checked) {
      const attributes = [...selectedPolicyAttributes]
      ids.forEach((id) => {
        if (!selectedPolicyAttributes.find((x) => x.name === id)) {
          const attr = getAttribute(id)
          if (attr) {
            attributes.push(attr)
          }
        }
      })
      setSelectedPolicyAttributes(attributes)
    } else {
      setSelectedPolicyAttributes(
        selectedPolicyAttributes.filter((x) => !ids.includes(x.name))
      )
    }
  }

  useEffect(() => {
    setRowSelectAllState(
      getRowSelectAllState(
        data?.filter((row) => row.rowConfig.selected === true)?.length,
        data?.length
      )
    )
  }, [data])

  return (
    <>
      <FlexRow className={'settingsColumn'}>
        <FlexColumnTree>
          <PolicyCategories
            attributes={policyAttributes}
            selectedAttributes={selectedPolicyAttributes}
            onChange={setSelectedCategory}
          />
        </FlexColumnTree>
        <FlexColumnRight>
          <FlexColumn className={'marginBottom12'}>
            <Search
              id={'id-select-policy-attribute-search'}
              className={'maxSearchWidth'}
              placeholder={t('common.search-in', {
                name: getCategoryName(selectedCategory)
              })}
              onChange={(v) => setSearch(v)}
            />
          </FlexColumn>
          <FlexRow className={'policyAttributesTableHeight'}>
            <FlexColumn className={'tableWidth'}>
              <Table
                columns={[
                  {
                    id: nameColumnId,
                    label: key('tableColOneLabel'),
                    sortable: true
                  },
                  {
                    id: pathColumnId,
                    label: key('tableColTwoLabel'),
                    sortable: true
                  },
                  {
                    id: 'uid',
                    label: 'Id',
                    index: 'hidden'
                  }
                ]}
                data={tableData || []}
                loading={!tableData}
                onSelect={handleRowSelect}
                onSelectAllPageItems={handleSelectAllPageItems}
                onSort={(_, sortBy) => setSorting(sortBy)}
                rowSelector={'multiSelection'}
                preferences={{
                  sortBy: sorting,
                  width: [
                    { columnId: 'name', width: 300 },
                    { columnId: 'path', width: 130 }
                  ]
                }}
                rowSelectAllState={rowSelectAllState}
                data-testid={'id-policy-attribute-table'}
                i18n={Retrievei18nItems()}
              />
            </FlexColumn>
            <FlexColumn className={'relatedCard'}>
              <Scrollbar>
                <RelatedItemsCard
                  attributes={policyAttributes}
                  selectedAttributes={selectedPolicyAttributes}
                  setHelpText={(data) => setHelpText(data)}
                />
              </Scrollbar>
            </FlexColumn>
          </FlexRow>
        </FlexColumnRight>
      </FlexRow>
      <Help
        {...helpText}
        onClose={() => setHelpText(null)}
        selectedState={(relatedItemId) => selectRelatedItem(relatedItemId)}
      />
    </>
  )
}
export default CreatePolicyStep2
