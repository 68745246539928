import React, {
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback
} from 'react'
import { useParams, useHistory } from 'react-router-dom'
import {
  Card,
  Accordion,
  Button,
  IconEllipsis,
  ContextualMenu,
  ButtonGroup
} from '@veneer/core'
import policiesContext from 'context/policies/policiesContext'
import PolicySettings from 'components/policies/settings'
import PolicyCategories from 'components/policies/categories'
import PolicyStatus from 'components/policies/status'
import ConfirmRemove from 'components/policies/modal/confirmRemove'
import VerticalDivider from 'common/controls/divider'
import configContext from 'context/config/configContext'
import PoliciesCategories from 'context/policies/PoliciesCategories'
import {
  DATE_FORMAT,
  TABLE_CONSTANTS,
  formatDate,
  getGroupName,
  getUserName
} from 'common/utilities'
import 'styles/global.scss'
import {
  FlexRowWithSpace,
  AccordionContent,
  FlexColumnTree,
  FlexColumnRight,
  FlexColumn,
  FlexRow
} from 'styles/styles'
import './index.scss'
import {
  RELOAD_EVENT,
  WRITE_SCOPES,
  DELETE_SCOPES,
  ToastIdEnum
} from '../constants'

const PolicyPreview = (props) => {
  const { getPolicy, selectedPolicy, removePolicies } =
    useContext(policiesContext)

  const { policyId } = useParams()
  const { baseurl, navigation, displayToast, accessControl, events } = props
  const { t, isWex, getCategoryName } = useContext(configContext)
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const history = navigation || useHistory()

  const [selectedCategory, setSelectedCategory] = useState([])
  const [showConfirmRemove, setShowConfirmRemove] = useState(false)

  const [allowWrite, setAllowWrite] = useState(isWex) // TO DO: isWex -> false
  const [allowDelete, setAllowDelete] = useState(isWex) // TO DO: isWex -> false

  const header = (subkey: string): string => {
    return t(`policy.table.header.${subkey}`)
  }

  const checkScopes = async () => {
    setAllowWrite(await accessControl.checkScopes(WRITE_SCOPES))
    setAllowDelete(await accessControl.checkScopes(DELETE_SCOPES))
  }

  useEffect(() => {
    // TO DO: remove !isWex
    if (accessControl && !isWex) {
      checkScopes()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessControl])

  const reload = useCallback(() => {
    if (policyId) {
      getPolicy(
        policyId,
        () => history.push(baseurl),
        (error) => displayToast(ToastIdEnum.GET_POLICY, error, 'negative')
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policyId, history, baseurl])

  useEffect(() => reload(), [policyId, reload])

  useEffect(() => {
    if (events) {
      events.addEventListener(RELOAD_EVENT, reload)
      return () => events.removeEventListener(RELOAD_EVENT, reload)
    }
  }, [events, reload])

  const policyDetails = useMemo(() => {
    return selectedPolicy
      ? {
          active: selectedPolicy.active,
          modifiedBy: getUserName(selectedPolicy.lastModifiedBy),
          modifiedAt: formatDate(
            selectedPolicy.lastModifiedAt,
            DATE_FORMAT.LAST_MODIFIED
          ),
          assignedTo: selectedPolicy.assignedTo?.length
            ? selectedPolicy.assignedTo
                .map((x) => getGroupName(x, t))
                .join(', ')
            : TABLE_CONSTANTS.NO_DATA
        }
      : {
          active: false,
          modifiedBy: '',
          modifiedAt: '',
          assignedTo: ''
        }
  }, [selectedPolicy, t])

  const policyCategories = useMemo(() => {
    return selectedPolicy
      ? PoliciesCategories.getPolicyCategories([], selectedPolicy.attributes)
          .map((x) => t(`policy.settings.categories.${x}`))
          .join(', ')
      : ''
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPolicy, selectedPolicy?.attributes, t])

  const selectedAttributes = useMemo(() => {
    return selectedPolicy
      ? PoliciesCategories.filterPolicyAttributes(
          selectedCategory,
          selectedPolicy.attributes
        )
      : null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPolicy, selectedPolicy?.attributes, selectedCategory])

  const onEdit = () => {
    history.push(`${baseurl}/edit/${selectedPolicy.id}`)
  }

  const closeConfirmRemove = () => setShowConfirmRemove(false)

  const doRemovePolicy = () => {
    closeConfirmRemove()
    removePolicies([selectedPolicy.id], () => {
      displayToast(ToastIdEnum.REMOVE_POLICY, t('policy.remove.success'))
      history.push(baseurl)
    })
  }

  const caption = (
    <FlexRowWithSpace className={'policyDetailsHeader'}>
      <div className={'nameWidth'}>
        <p>{selectedPolicy?.name}</p>
      </div>
      <ButtonGroup>
        {allowWrite && (
          <Button id={'editButton'} appearance={'secondary'} onClick={onEdit}>
            {t('common.edit')}
          </Button>
        )}
        {allowDelete && (
          <ContextualMenu
            placement={'bottom-end'}
            portal={true}
            id={'moreActions'}
            options={[
              {
                value: 0,
                label: t('common.remove'),
                disabled: selectedPolicy ? selectedPolicy.active : true
              }
            ]}
            onClick={() => setShowConfirmRemove(true)}
          >
            <Button
              id={'moreButton'}
              appearance={'ghost'}
              leadingIcon={<IconEllipsis />}
            />
          </ContextualMenu>
        )}
      </ButtonGroup>
    </FlexRowWithSpace>
  )

  const content = (
    <AccordionContent>
      <FlexRow className={'paddingBottom16'}>
        <FlexColumn>
          <div className={'headerFont1 columnWidth'}>
            {header('status').toUpperCase()}
          </div>
          <div className={'valueFont1'} data-testid={'id-policy-status'}>
            <PolicyStatus active={policyDetails.active} />
          </div>
        </FlexColumn>
        <VerticalDivider />
        <FlexColumn>
          <div className={'headerFont1 columnWidth'}>
            {header('modifiedBy').toUpperCase()}
          </div>
          <div className={'valueFont1'} data-testid={'id-policy-modified-by'}>
            {policyDetails.modifiedBy}
          </div>
        </FlexColumn>
        <VerticalDivider />
        <FlexColumn>
          <div className={'headerFont1'}>
            {header('modifiedAt').toUpperCase()}
          </div>
          <div className={'valueFont1'} data-testid={'id-policy-modified-at'}>
            {policyDetails.modifiedAt}
          </div>
        </FlexColumn>
      </FlexRow>
      <FlexRow className={'paddingBottom8'}>
        <div className={'headerFont2 labelWidth'}>{header('category')}</div>
        <div className={'valueFont2'} data-testid={'id-policy-categories'}>
          {policyCategories}
        </div>
      </FlexRow>
      <FlexRow>
        <div className={'headerFont2 labelWidth'}>{header('assignedTo')}</div>
        <div className={'valueFont2'} data-testid={'id-policy-assigned-to'}>
          {policyDetails.assignedTo}
        </div>
      </FlexRow>
    </AccordionContent>
  )

  return (
    <FlexColumn id={'policyPreview'}>
      <Card
        content={
          <Accordion
            className={'rightPaddingAccordion maxWidthAccordion'}
            items={[
              {
                id: 'policyDetailsCard',
                expanded: true,
                content: content,
                header: { centralArea: caption }
              }
            ]}
            behavior={'singleExpand'}
            id={'accordion-details'}
          />
        }
        border={'dropShadow'}
      />
      <FlexRow className={'marginTop20' + (isWex ? ' marginBottom12' : '')}>
        <FlexColumnTree>
          <PolicyCategories
            attributes={selectedPolicy?.attributes || []}
            onChange={setSelectedCategory}
          />
        </FlexColumnTree>
        <FlexColumnRight>
          <PolicySettings
            attributes={selectedAttributes}
            searchCategory={getCategoryName(selectedCategory)}
            preview={true}
          />
        </FlexColumnRight>
      </FlexRow>
      <ConfirmRemove
        show={showConfirmRemove}
        onCancel={closeConfirmRemove}
        onRemove={doRemovePolicy}
      />
    </FlexColumn>
  )
}

export default PolicyPreview
