import React, { useContext, useEffect, useState, memo, useMemo } from 'react'
import settingsContext from 'context/settings/settingsContext'
import configContext from 'context/config/configContext'
import { Button, ButtonGroup, Table, CustomImage } from '@veneer/core'
import { ColumnIndexTypes } from '@veneer/core/dist/scripts/table'
import { ArrayAttributeDescription } from 'context/policies/PoliciesConfiguration'
import PoliciesHelper from 'context/policies/PoliciesHelper'
import {
  FlexRow,
  FlexColumn,
  TextBlack,
  PreviewLabel,
  MaxTableHeight
} from 'styles/styles'
import PreviewItem from 'components/policies/settings/attributes/device/previewItem'
import Retrievei18nItems from 'common/utilityItems/Retrievei18nItems'
import { getRowSelectAllState } from 'common/utilities'
import 'styles/global.scss'
import SelectAppModal, { AppDeploymentEnum } from './SelectAppModal'
// import AppDeploymentModal from './AppDeploymentModal' // NO_APP_CONFIG

const tablePreferences = {
  width: [
    { columnId: AppDeploymentEnum.NAME, width: 240 },
    { columnId: AppDeploymentEnum.VERSION, width: 120 },
    { columnId: AppDeploymentEnum.PROVIDER, width: 160 }
    // { columnId: AppDeploymentEnum.CONFIG_ID, width: 20 } // NO_APP_CONFIG
  ]
}

const AppDeploymentControl = (props) => {
  const description: ArrayAttributeDescription = props.description
  const {
    compliance,
    attributes,
    data: { deviceSettings },
    onAttributeChange
  } = props
  const { attribute } = description

  const { tt } = useContext(configContext)
  const { isEnabled, addDisabled, removeDisabled } = useContext(settingsContext)

  const getLocalized = (key: string, params?): string =>
    tt(props.localizationPath, key, params)

  const [value, setValue] = useState(
    PoliciesHelper.getData(description, deviceSettings)
  )
  const [tableData, setTableData] = useState([])
  const [rowSelectAllState, setRowSelectAllState] = useState(undefined)
  const [selectedCount, setSelectedCount] = useState(0)
  const [showSelect, setShowSelect] = useState(false)
  // const [showConfig, setShowConfig] = useState(null) // NO_APP_CONFIG

  useEffect(
    () => setValue(PoliciesHelper.getData(description, deviceSettings)),
    [deviceSettings]
  )

  useEffect(() => {
    // make sure that attribute value is up-to-date, otherwise just wait for sync
    const apps = PoliciesHelper.getData(description, deviceSettings)
    if (attributes && JSON.stringify(apps) === JSON.stringify(value)) {
      PoliciesHelper.update(
        description,
        value,
        (ids, value) => (value ? removeDisabled(ids) : addDisabled(ids)),
        props.onSettingsChanges,
        attributes
      )
    }
  }, [value, attributes])

  const setData = (newTableData) => {
    setTableData(newTableData)
    const settings = [...deviceSettings]
    const newValue = newTableData.map((x) => x.rowConfig.item)
    PoliciesHelper.setData(description, settings, newValue)
    onAttributeChange({ ...props.data, deviceSettings: settings })
  }

  const onChange = (v) => setData(v.map((x, i) => tableItem(x, i)))
  const onRemove = () => setData(tableData.filter((x) => !x.rowConfig.selected))

  const tableItem = (item, i) => {
    const selected = i < tableData.length && !!tableData[i].rowConfig.selected
    const id = item[AppDeploymentEnum.UUID]
    /* NO_APP_CONFIG
    const button = (text) => (
      <Button
        small={true}
        appearance={'ghost'}
        onClick={() => setShowConfig(id)}
      >
        {getLocalized(text)}
      </Button>
    )
    */
    /* NO_APP_CONFIG
    const noData = () => (
      <div className={'marginLeft12'}>{TABLE_CONSTANTS.NO_DATA}</div>
    )
    const configExists = !!item[AppDeploymentEnum.CONFIG_ID]
    */
    return {
      id,
      [AppDeploymentEnum.NAME]: (
        <FlexRow className={'alignCenter'}>
          <CustomImage size={36} src={item[AppDeploymentEnum.ICON]} />
          <span className={'marginLeft4'}>{item[AppDeploymentEnum.NAME]}</span>
        </FlexRow>
      ),
      [AppDeploymentEnum.VERSION]: item[AppDeploymentEnum.VERSION],
      [AppDeploymentEnum.PROVIDER]: item[AppDeploymentEnum.PROVIDER],
      /* NO_APP_CONFIG
      [AppDeploymentEnum.CONFIG_ID]: (
        <FlexColumn className={'alignStart'}>
          {onAttributeChange
            ? item[AppDeploymentEnum.MOC_ID]
              ? button(configExists ? 'common.edit' : 'common.set')
              : noData()
            : configExists
            ? button('view-detail')
            : noData()}
        </FlexColumn>
      ),*/
      rowConfig: { selected, item }
    }
  }

  useEffect(
    () => setTableData(value ? value.map((x, i) => tableItem(x, i)) : []),
    [value]
  )

  useEffect(() => {
    const selected = tableData.filter((x) => x.rowConfig.selected).length
    setSelectedCount(selected)
    setRowSelectAllState(getRowSelectAllState(selected, tableData.length))
  }, [tableData])

  const tableColumns = useMemo(() => {
    const id = 'id'
    const index: ColumnIndexTypes = 'hidden'
    return [
      { id, label: id, index },
      { id: AppDeploymentEnum.NAME, label: getLocalized('app-name') },
      { id: AppDeploymentEnum.VERSION, label: getLocalized('app-version') },
      { id: AppDeploymentEnum.PROVIDER, label: getLocalized('app-provider') }
      // { id: AppDeploymentEnum.CONFIG_ID, label: getLocalized('app-config') } // NO_APP_CONFIG
    ]
  }, [])

  const handleRowSelect = (event, rowId) => {
    const { checked } = event.target
    const found = tableData.find(({ id }) => id === rowId)
    if (found) {
      found.rowConfig.selected = checked
      setTableData([...tableData])
    }
  }

  const handleSelectAllPageItems = (event) => {
    const { checked } = event.target
    setTableData(
      tableData.map((row) => {
        row.rowConfig.selected = checked
        return row
      })
    )
  }

  const tableHeader = (text) =>
    !onAttributeChange ? (
      <PreviewLabel>{text}</PreviewLabel>
    ) : (
      <TextBlack className={'marginBottom12'} disabled={!enabled}>
        {text}
      </TextBlack>
    )

  const enabled = isEnabled(attribute)
  /* NO_APP_CONFIG
  const configItem = useMemo(
    () => (showConfig ? tableData.find((x) => x.id === showConfig) : null),
    [showConfig]
  )
  */
  return (
    <>
      <FlexColumn>
        {tableHeader(getLocalized('app-list'))}
        {onAttributeChange && (
          <FlexRow className={'marginBottom12 alignCenter'}>
            <ButtonGroup>
              <Button
                appearance={'secondary'}
                disabled={!enabled}
                onClick={() => setShowSelect(true)}
                id={'addAppDeployment'}
              >
                {getLocalized(tableData.length ? 'change-app' : 'select-app')}
              </Button>
              <Button
                appearance={'secondary'}
                disabled={!enabled || !selectedCount}
                onClick={onRemove}
                id={'removeAppDeployment'}
              >
                {getLocalized('common.remove')}
              </Button>
            </ButtonGroup>
            <TextBlack className={'marginLeft12'} disabled={!enabled}>
              {getLocalized('common.n-items-selected', {
                count: tableData.length
              })}
            </TextBlack>
          </FlexRow>
        )}
        <MaxTableHeight>
          <Table
            columns={tableColumns}
            data={tableData}
            onSelect={handleRowSelect}
            onSelectAllPageItems={handleSelectAllPageItems}
            rowSelector={
              onAttributeChange && enabled ? 'multiSelection' : undefined
            }
            rowSelectAllState={rowSelectAllState}
            className={
              onAttributeChange
                ? 'paddingSecondCol4 paddingFifthCol4'
                : 'paddingFirstCol4 paddingFourthCol4'
            }
            data-testid={'id-app-deployment-table'}
            preferences={tablePreferences}
            i18n={Retrievei18nItems()}
          />
        </MaxTableHeight>
        {compliance && (
          <PreviewItem compliance={compliance} className={'marginTop4'} />
        )}
      </FlexColumn>
      {showSelect && (
        <SelectAppModal
          localizationPath={props.localizationPath}
          onChange={onChange}
          onClose={() => setShowSelect(false)}
          value={value}
        />
      )}
      {/* NO_APP_CONFIG
        showConfig && (
        <AppDeploymentModal
          onClose={() => setShowConfig(null)}
          item={configItem?.rowConfig.item}
          localizationPath={props.localizationPath}
          onChange={(configId) => {
            if (configItem) {
              configItem.rowConfig.item[AppDeploymentEnum.CONFIG_ID] = configId
              onChange(tableData.map(({ rowConfig: { item } }) => item))
            }
          }}
          preview={!onAttributeChange}
          configId={configItem?.rowConfig.item[AppDeploymentEnum.CONFIG_ID]}
          tableHeader={configItem?.[AppDeploymentEnum.NAME]}
        />
      )*/}
    </>
  )
}

export default memo(AppDeploymentControl)
