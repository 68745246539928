import React, { useContext, useState, useMemo } from 'react'
import configContext from 'context/config/configContext'
import { Accordion, Search, Card, Scrollbar } from '@veneer/core'
import SettingsProvider from 'context/settings/SettingsProvider'
import PolicyAttribute from 'components/policies/settings/attributes'
import PolicyHeaderRightWrapper from './attributes/header/right/PolicyHeaderRightWrapper'
import PolicyHeaderContent from './attributes/header/content'
import PolicySettingsCardWrapper from './card/PolicySettingsCardWrapper'
import PolicySettingsCard from './card'
import Help from 'components/policies/modal/help'
import NoItemsCard from 'common/controls/noItems'
import { isAdvanced } from 'common/utilities'
import PoliciesConfiguration, {
  PolicyAttributeDescriptionEx
} from 'context/policies/PoliciesConfiguration'
import PoliciesHelper from 'context/policies/PoliciesHelper'
import configData from 'common/config/settings.json'
import { FlexRowWithSpace, FlexColumnGrow, cssFullHeight } from 'styles/styles'
import 'styles/global.scss'
import './index.scss'

const notificationPath = 'policy.settings.notification'

const PolicySettings = (props) => {
  const { t } = useContext(configContext)
  const key = (key: string, path?: string): string => {
    return t(`${path || 'policy.details'}.${key}`)
  }

  const {
    attributes,
    preview,
    entitlements,
    policyConstraints,
    devicePolicy,
    searchCategory
  } = props

  const [search, setSearch] = useState('')
  const [helpText, setHelpText] = useState(null)

  const onSettingsChanges = (settings) => {
    settings.forEach((setting) => {
      const foundAttribute = attributes.find((item) =>
        item.deviceSettings.find((x) => setting.name === x.name)
      )
      if (foundAttribute) {
        const foundSettings = foundAttribute.deviceSettings.find(
          (x) => setting.name === x.name
        )
        if (foundSettings) {
          if (foundSettings.value !== setting.value) {
            foundSettings.value = setting.value
            foundAttribute.deviceSettings = [...foundAttribute.deviceSettings]
            if (props.onAttributeChange) {
              props.onAttributeChange(foundAttribute)
            }
          }
        }
      }
    })
  }

  const accordionItems = useMemo(() => {
    if (!attributes) {
      return null
    }

    const items = []
    const searchLowerCase = search.toLowerCase()
    const compare = searchLowerCase.length
      ? (description) => {
          const name = key(
            'name',
            PoliciesConfiguration.getPolicyAttrLocPath(description.id)
          )
          return name.toLowerCase().includes(searchLowerCase)
        }
      : () => true

    for (const attribute of PoliciesConfiguration.sortPolicyAttributes(
      attributes,
      t
    )) {
      const description: PolicyAttributeDescriptionEx =
        PoliciesConfiguration.getPolicyAttributeDescription(
          attribute.name,
          policyConstraints,
          devicePolicy ? null : configData.settings.device.specific.settings
        )
      if (compare(description)) {
        let nonEntitled = false
        const advanced = isAdvanced(attribute.metadata.entitlements)
        const notifications = []
        if (!preview) {
          // Unentitled inline notification
          nonEntitled =
            advanced &&
            Array.isArray(entitlements) &&
            !attribute.metadata.entitlements.some((x) =>
              entitlements.includes(x)
            )
          if (nonEntitled) {
            notifications.push({
              type: 'warning',
              title: key('advanced.title', notificationPath),
              description: key('advanced.description', notificationPath)
            })
          }
          // Informative inline notification
          const infos = PoliciesHelper.getInfoNotification(description)
          infos.forEach((info) => {
            const notification = {
              type: 'informative',
              description: key(`${info.key}.description`, notificationPath),
              help: info.help
            }
            const titleKey = `${info.key}.title`
            const title = key(titleKey, notificationPath)
            if (!title.endsWith(titleKey)) {
              notification['title'] = title
            }
            notifications.push(notification)
          })
        }
        items.push({
          id: attribute.name,
          expanded: false,
          content: (
            <PolicyAttribute
              item={attribute}
              attributes={attributes}
              description={description}
              onAttributeChange={props.onAttributeChange}
              onSettingsChanges={onSettingsChanges}
              preview={preview}
              notifications={notifications}
            />
          ),
          header: {
            centralArea: (
              <PolicyHeaderContent
                description={description}
                search={searchLowerCase}
                advanced={advanced}
                setHelpText={(data) => setHelpText(data)}
              />
            ),
            endArea: preview ? null : (
              <PolicyHeaderRightWrapper
                nonEntitled={nonEntitled}
                description={description}
                onRemove={() => props.onRemove(description.id)}
              />
            )
          }
        })
      }
    }
    return items
  }, [attributes, search, policyConstraints])

  const policySettingsCard = () => {
    return preview ? (
      <PolicySettingsCard />
    ) : (
      <PolicySettingsCardWrapper
        onWarning={props.onWarning}
        onError={props.onError}
        showErrors={props.showErrors}
        attributes={attributes}
      />
    )
  }

  return (
    <SettingsProvider>
      <FlexColumnGrow className={'fullHeight'}>
        {searchCategory && (
          <FlexRowWithSpace className={'marginBottom12 alignCenter'}>
            <Search
              id={'id-policy-attribute-search'}
              className={'maxSearchWidth'}
              placeholder={t('common.search-in', { name: searchCategory })}
              onChange={(v) => setSearch(v)}
              disabled={!attributes?.length}
            />
            {props.actions}
          </FlexRowWithSpace>
        )}
        {accordionItems?.length ? (
          <Card
            content={
              <>
                {policySettingsCard()}
                <div className={'policySettingsAccordion'}>
                  <Scrollbar customStyle={cssFullHeight}>
                    <Accordion
                      className={
                        'rightPaddingAccordion overflowVisibleAccordion'
                      }
                      items={accordionItems}
                      behavior={'singleExpand'}
                      id="accordion-settings"
                    />
                  </Scrollbar>
                </div>
              </>
            }
            border={props.outlined ? 'outlined' : 'dropShadow'}
            className={
              searchCategory
                ? 'policySettingsSearchHeight'
                : 'policySettingsHeight'
            }
          />
        ) : (
          <NoItemsCard
            indicator={!attributes}
            title={key('settings.notFound')}
            description={key(
              attributes?.length
                ? 'settings.notFoundDescriptionSearch'
                : devicePolicy
                ? 'settings.notFoundDescriptionDevice'
                : 'settings.notFoundDescription'
            )}
          />
        )}
        <Help {...helpText} onClose={() => setHelpText(null)} />
      </FlexColumnGrow>
    </SettingsProvider>
  )
}

export default PolicySettings
