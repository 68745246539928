export enum DeviceAccessRolesModalEnum {
  NONE,
  ADD = 'add',
  EDIT = 'edit',
  COPY = 'copy'
}

export enum DeviceAccessEnum {
  // roles
  ID = 'id',
  NAME = 'name',
  TYPE = 'type', // column only
  // permissions
  PERMISSION = 'permission',
  SIGN_IN = 'signIn',
  ROLES = 'roles',
  CHECK = 'check'
}

export enum DeviceAccessRolesEnum {
  GUEST = '0a52b510-5db2-49f2-b95d-7f5c37c50235',
  ADMIN = '42e5e2d7-6c01-42b8-b086-ee8a1a2c25de',
  USER = 'a69a546c-dd68-4e4b-8302-97cd6471a0a4'
}

export const getDefaultRoleName = (role: DeviceAccessRolesEnum): string => {
  switch (role) {
    case DeviceAccessRolesEnum.GUEST:
      return 'roles-guest'
    case DeviceAccessRolesEnum.ADMIN:
      return 'roles-admin'
    case DeviceAccessRolesEnum.USER:
      return 'roles-user'
    default:
      return null
  }
}
