import { jsonParse } from 'common/utilities'
import { Attribute, DeviceSettings } from 'common/model/api/Policy'
import {
  enableCallBackType,
  setValueCallBackType,
  DropBoxDescription,
  PasswordDescription,
  PolicyItemPrimitives,
  TextBoxDescription,
  ToggleDescription,
  MultiSelectCheckboxDescription,
  FwUpdateVersionDescription,
  FwUpdateScheduleDescription,
  PriorityListControlDescription,
  TrayAdministrationDescription,
  PolicyAttributeDescription,
  DeviceSettingDescription,
  ArrayItemsAttributeDescription,
  ArrayAttributeDescription,
  ObjectAttributeDescription,
  SimpleTableDescription,
  ObjectDropboxDescription,
  FileUploadDescription,
  HomeScreenAppsDescription,
  AccessControlPermissionDescription
} from './PoliciesConfiguration'
import { PolicyAttributeEnum } from './PoliciesCategories'
import { getError, isTrueString } from 'common/utilities'
import configData from 'common/config/settings.json'
import { MessageTypesEnum } from './PoliciesErrors'

export default class PoliciesHelper {
  public static getInfoNotification(descr: PolicyAttributeDescription): {
    key: string
    help?: string
  }[] {
    if (
      descr.deviceSettings.some(
        ({ type }) => type === PolicyItemPrimitives.PasswordView
      )
    ) {
      return [{ key: 'password' }]
    }

    const notifications = [
      {
        notification: { key: 'threshold' },
        attributes: [
          PolicyAttributeEnum.Cartridge_Threshold_Cyan,
          PolicyAttributeEnum.Cartridge_Threshold_Magenta,
          PolicyAttributeEnum.Cartridge_Threshold_Yellow,
          PolicyAttributeEnum.Cartridge_Threshold_Black,
          PolicyAttributeEnum.Drum_Threshold_Cyan,
          PolicyAttributeEnum.Drum_Threshold_Magenta,
          PolicyAttributeEnum.Drum_Threshold_Yellow,
          PolicyAttributeEnum.Drum_Threshold_Black
        ]
      },
      {
        notification: { key: 'password-app-deployment' },
        attributes: [PolicyAttributeEnum.App_Deployment]
      },
      {
        notification: { key: 'password-proxy-server' },
        attributes: [PolicyAttributeEnum.Proxy_Server]
      },
      {
        notification: { key: 'password-smtp-gateway' },
        attributes: [PolicyAttributeEnum.Smtp_Gateway]
      },
      {
        notification: { key: 'password-outgoing-servers' },
        attributes: [PolicyAttributeEnum.Outgoing_Servers]
      },
      {
        notification: { key: 'password-ldap-setup' },
        attributes: [PolicyAttributeEnum.Ldap_Setup]
      },
      {
        notification: { key: 'password-wifi-direct' },
        attributes: [PolicyAttributeEnum.WiFi_Direct]
      },
      {
        notification: { key: 'power' },
        attributes: [PolicyAttributeEnum.Energy_Settings]
      },
      {
        notification: { key: PolicyAttributeEnum.Ca_Certificate },
        attributes: [PolicyAttributeEnum.Ca_Certificate]
      },
      {
        notification: { key: PolicyAttributeEnum.Cartridge_Policy },
        attributes: [PolicyAttributeEnum.Cartridge_Policy]
      },
      {
        notification: { key: PolicyAttributeEnum.Cartridge_Protection },
        attributes: [PolicyAttributeEnum.Cartridge_Protection]
      },
      {
        notification: { key: PolicyAttributeEnum.Device_Access_Ctrl },
        attributes: [PolicyAttributeEnum.Device_Access_Ctrl]
      },
      {
        notification: {
          key: PolicyAttributeEnum.Auto_FW_Update,
          help: configData.settings.solDevices.join('\n')
        },
        attributes: [PolicyAttributeEnum.Auto_FW_Update]
      },
      {
        notification: { key: 'ds-info' },
        attributes: [
          PolicyAttributeEnum.Auto_FW_Update,
          PolicyAttributeEnum.Firmware_Update,
          PolicyAttributeEnum.Legacy_Firmware_Upgrade,
          PolicyAttributeEnum.Remote_FW_Update
        ]
      }
    ]
    return notifications
      .filter(({ attributes }) => attributes.includes(descr.id))
      .map(({ notification }) => notification)
  }

  public static isBooleanDescription(arg: DeviceSettingDescription): boolean {
    return [
      PolicyItemPrimitives.Toggle,
      PolicyItemPrimitives.ToggleView
    ].includes(arg.type)
  }

  // Several fields representing one object
  public static isObjectDescription(arg: DeviceSettingDescription): boolean {
    return [
      PolicyItemPrimitives.DelayLowMsgThreshold,
      PolicyItemPrimitives.TrayAdministration,
      PolicyItemPrimitives.EmailMessage,
      PolicyItemPrimitives.FaxReceive,
      PolicyItemPrimitives.FaxSend,
      PolicyItemPrimitives.FileName,
      PolicyItemPrimitives.RadioTextBox,
      PolicyItemPrimitives.ObjectDropBox,
      PolicyItemPrimitives.SioConfig,
      PolicyItemPrimitives.WebEncryption
    ].includes(arg.type)
  }

  // JSON string representing an object
  public static isJsonObjectDescription(
    arg: DeviceSettingDescription
  ): boolean {
    return arg.type === PolicyItemPrimitives.FileUpload
  }

  // JSON string representing array of objects
  public static isArrayDescription(arg: DeviceSettingDescription): boolean {
    return [
      PolicyItemPrimitives.MultiSelectCheckbox,
      PolicyItemPrimitives.HomeScreenLanguageAndKeyboard,
      PolicyItemPrimitives.HomeScreenApplications,
      PolicyItemPrimitives.PriorityList,
      PolicyItemPrimitives.OutgoingServers,
      PolicyItemPrimitives.RestrictColor,
      PolicyItemPrimitives.CopyStamps,
      PolicyItemPrimitives.SimpleTable,
      PolicyItemPrimitives.AppDeployment,
      PolicyItemPrimitives.WallpaperUpload,
      PolicyItemPrimitives.WeeklySchedule,
      PolicyItemPrimitives.HolidaySchedule,
      PolicyItemPrimitives.QuickSets,
      PolicyItemPrimitives.FwUpdateVersion,
      PolicyItemPrimitives.AccessCtrlRoles,
      PolicyItemPrimitives.AccessCtrlPermissions
    ].includes(arg.type)
  }

  public static getWarning(
    description: ToggleDescription | DropBoxDescription,
    value: boolean | string
  ): { message: string; type: MessageTypesEnum } {
    const returnWarning = (warning) => {
      const { message, messageType } = warning
      return { message, type: messageType || MessageTypesEnum.WARNING }
    }
    const warnings = description['warnings']
    if (warnings) {
      // Handle DropBoxDescription
      const warning = warnings.find((item) => item.value === value)
      if (warning) {
        return returnWarning(warning)
      }
    } else {
      // Handle ToggleDescription
      const warning = description['warning']
      if (warning && warning.value === value) {
        return returnWarning(warning)
      }
    }
    return null
  }

  public static getError(
    description: TextBoxDescription | PasswordDescription,
    value: string
  ): { message: string; params } {
    const { errors } = description
    return getError(errors, value)
  }

  static extractData(
    description: DeviceSettingDescription,
    data: DeviceSettings[],
    field: string
  ): Record<string, string> {
    const obj = {}
    const name = description.attribute + '.'
    data
      .filter((a) => a.name.startsWith(name) && a.name.length > name.length)
      .forEach((a) => (obj[a.name.slice(name.length)] = a[field]))
    return obj
  }

  public static getCompliance(
    description:
      | ObjectAttributeDescription
      | TrayAdministrationDescription
      | ObjectDropboxDescription
      | FileUploadDescription,
    data: DeviceSettings[]
  ): Record<string, string> {
    return PoliciesHelper.extractData(description, data, 'compliance')
  }

  public static getData(
    description:
      | MultiSelectCheckboxDescription
      | PriorityListControlDescription
      | ArrayItemsAttributeDescription
      | ArrayAttributeDescription
      | FwUpdateVersionDescription
      | SimpleTableDescription
      | HomeScreenAppsDescription
      | AccessControlPermissionDescription,
    data: DeviceSettings[]
  ): []
  public static getData(
    description:
      | ObjectAttributeDescription
      | TrayAdministrationDescription
      | ObjectDropboxDescription
      | FileUploadDescription,
    data: DeviceSettings[]
  ): Record<string, string>
  public static getData(
    description: ToggleDescription,
    data: DeviceSettings[]
  ): boolean
  public static getData(
    description:
      | TextBoxDescription
      | PasswordDescription
      | DropBoxDescription
      | FwUpdateScheduleDescription,
    data: DeviceSettings[]
  ): string

  public static getData(
    description: DeviceSettingDescription,
    data: DeviceSettings[]
  ): boolean | string | [] | Record<string, string> {
    if (PoliciesHelper.isObjectDescription(description)) {
      return PoliciesHelper.extractData(description, data, 'value')
    }
    const attribute = data.find((a) => a.name === description.attribute)
    if (PoliciesHelper.isJsonObjectDescription(description)) {
      return attribute ? jsonParse(attribute.value, {}) : {}
    }
    if (PoliciesHelper.isArrayDescription(description)) {
      return attribute ? jsonParse(attribute.value) : []
    }
    if (PoliciesHelper.isBooleanDescription(description)) {
      return attribute && isTrueString(attribute.value)
    }
    // string
    return attribute && attribute.value !== null ? attribute.value : ''
  }

  public static setData(
    description:
      | MultiSelectCheckboxDescription
      | PriorityListControlDescription
      | ArrayItemsAttributeDescription
      | ArrayAttributeDescription
      | FwUpdateVersionDescription
      | SimpleTableDescription
      | HomeScreenAppsDescription
      | AccessControlPermissionDescription,
    data: DeviceSettings[],
    value: []
  ): void
  public static setData(
    description:
      | ObjectAttributeDescription
      | TrayAdministrationDescription
      | ObjectDropboxDescription
      | FileUploadDescription,
    data: DeviceSettings[],
    value: Record<string, string>
  ): void
  public static setData(
    description: ToggleDescription,
    data: DeviceSettings[],
    value: boolean
  ): void
  public static setData(
    description:
      | TextBoxDescription
      | PasswordDescription
      | DropBoxDescription
      | FwUpdateScheduleDescription,
    data: DeviceSettings[],
    value: string
  ): void

  public static setData(
    description: DeviceSettingDescription,
    data: DeviceSettings[],
    value: boolean | string | [] | Record<string, string>
  ): void {
    if (PoliciesHelper.isObjectDescription(description)) {
      const prefix = description.attribute + '.'
      // Clear all existing fields of this object
      const indexes = data.reduce(
        (acc, x, i) => (x.name.startsWith(prefix) ? [...acc, i] : acc),
        []
      )
      indexes.reverse()
      indexes.forEach((i) => data.splice(i, 1))
      // Add new object fields
      for (const prop in value as Record<string, string>) {
        data.push({ name: prefix + prop, value: value[prop] })
      }
      return
    }
    const attribute = data.find((a) => a.name === description.attribute)
    const jsonString = (val, def) => (val ? JSON.stringify(val) : def)
    const newValue = PoliciesHelper.isJsonObjectDescription(description)
      ? jsonString(value, '{}')
      : PoliciesHelper.isArrayDescription(description)
      ? jsonString(value, '[]')
      : PoliciesHelper.isBooleanDescription(description)
      ? String(value)
      : value.toString() // string
    if (attribute) {
      attribute.value = newValue
    } else {
      data.push({ name: description.attribute, value: newValue })
    }
  }

  public static update(
    description:
      | MultiSelectCheckboxDescription
      | PriorityListControlDescription
      | ArrayItemsAttributeDescription
      | ArrayAttributeDescription
      | FwUpdateVersionDescription
      | SimpleTableDescription
      | HomeScreenAppsDescription
      | AccessControlPermissionDescription,
    value: [],
    enableCallback: enableCallBackType,
    setValueCallback: setValueCallBackType,
    attributes: Attribute[]
  ): void
  public static update(
    description:
      | ObjectAttributeDescription
      | TrayAdministrationDescription
      | ObjectDropboxDescription
      | FileUploadDescription,
    value: Record<string, string>,
    enableCallback: enableCallBackType,
    setValueCallback: setValueCallBackType,
    attributes: Attribute[]
  ): void
  public static update(
    description: ToggleDescription,
    value: boolean,
    enableCallback: enableCallBackType,
    setValueCallback: setValueCallBackType,
    attributes: Attribute[]
  ): void
  public static update(
    description:
      | TextBoxDescription
      | PasswordDescription
      | DropBoxDescription
      | FwUpdateScheduleDescription,
    value: string,
    enableCallback: enableCallBackType,
    setValueCallback: setValueCallBackType,
    attributes: Attribute[]
  ): void
  public static update(
    description:
      | ToggleDescription
      | TextBoxDescription
      | PasswordDescription
      | DropBoxDescription
      | MultiSelectCheckboxDescription
      | FwUpdateVersionDescription
      | FwUpdateScheduleDescription
      | PriorityListControlDescription
      | TrayAdministrationDescription
      | ArrayItemsAttributeDescription
      | ArrayAttributeDescription
      | HomeScreenAppsDescription
      | AccessControlPermissionDescription
      | ObjectAttributeDescription
      | SimpleTableDescription
      | ObjectDropboxDescription,
    value: never,
    enableCallback: enableCallBackType,
    setValueCallback: setValueCallBackType,
    attributes: Attribute[]
  ): void {
    if (description.update) {
      description.update(value, enableCallback, setValueCallback, attributes)
    }
  }

  public static getPassword(): string {
    return '\u204E\u204E\u204E\u204E\u204E\u204E\u204E\u204E\u204E\u204E'
  }
}
