import React, { memo, useContext, useMemo, useEffect, useState } from 'react'
import settingsContext from 'context/settings/settingsContext'
import configContext from 'context/config/configContext'
import { Button, ButtonGroup } from '@veneer/core'
import { ArrayAttributeDescription } from 'context/policies/PoliciesConfiguration'
import PoliciesHelper from 'context/policies/PoliciesHelper'
import { FlexColumn, FlexRow, PreviewLabel, TextBlack } from 'styles/styles'
import PreviewItem from 'components/policies/settings/attributes/device/previewItem'
import WarningMessage from '../WarningMessage'
import OutgoingServersTable from './OutgoingServersTable'
import OutgoingServerModal, {
  outgoingServerEnum,
  usageItems
} from './OutgoingServersModal'
import { abcSort, TABLE_CONSTANTS } from 'common/utilities'
import 'styles/global.scss'
import OutgoingServersItems from './OutgoingServersItems'
import { MessageTypesEnum } from 'context/policies/PoliciesErrors'

const maxServers = 20

const defaultUsage = {
  [outgoingServerEnum.EMAIL]: false,
  [outgoingServerEnum.ALERT]: false,
  [outgoingServerEnum.FAX]: false,
  [outgoingServerEnum.AUTOSEND]: false
}

const orderKey = (x) => x + outgoingServerEnum.ORDER
const defaultOrder = () => ({
  [orderKey(outgoingServerEnum.EMAIL)]: 0,
  [orderKey(outgoingServerEnum.ALERT)]: 0,
  [orderKey(outgoingServerEnum.FAX)]: 0,
  [orderKey(outgoingServerEnum.AUTOSEND)]: 0
})

const defaultData = {
  [outgoingServerEnum.ADDRESS]: '',
  [outgoingServerEnum.PORT]: '',
  [outgoingServerEnum.SPLIT_EMAIL]: '0',
  [outgoingServerEnum.SSL]: false,
  [outgoingServerEnum.VALIDATE_CERT]: false,
  [outgoingServerEnum.AUTH]: false,
  [outgoingServerEnum.CREDENTIAL]:
    OutgoingServersItems.allCredentialModes[0].value,
  ...defaultUsage
}

const defaultTableItems = () => ({
  [outgoingServerEnum.EMAIL]: [],
  [outgoingServerEnum.ALERT]: [],
  [outgoingServerEnum.FAX]: [],
  [outgoingServerEnum.AUTOSEND]: []
})

const OutgoingServersControl = (props) => {
  const description: ArrayAttributeDescription = props.description
  const {
    id,
    compliance,
    attributes,
    data: { deviceSettings },
    onAttributeChange
  } = props
  const { attribute } = description

  const { tt } = useContext(configContext)
  const {
    isEnabled,
    addDisabled,
    removeDisabled,
    addError,
    removeError,
    displayAllErrors
  } = useContext(settingsContext)

  const getLocalized = (key: string, params?): string =>
    tt(props.localizationPath, key, params)

  const enabled = isEnabled(attribute)
  const value = useMemo(
    () => PoliciesHelper.getData(description, deviceSettings),
    [deviceSettings]
  )
  const [tableData, setTableData] = useState(defaultTableItems())
  const [uniqueCount, setUniqueCount] = useState(0)
  const [selectedCount, setSelectedCount] = useState(0)
  const [selectedItem, setSelectedItem] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [error, setError] = useState(false)
  const [displayError, setDisplayError] = useState(displayAllErrors)
  const showError = displayError || displayAllErrors
  const errorState = enabled && !value.length

  useEffect(() => {
    setError(showError && errorState)
    errorState ? addError(id, attribute, showError) : removeError(id, attribute)
  }, [errorState, showError])

  useEffect(() => {
    // make sure that attribute value is up-to-date, otherwise just wait for sync
    const servers = PoliciesHelper.getData(description, deviceSettings)
    if (attributes && JSON.stringify(servers) === JSON.stringify(value)) {
      PoliciesHelper.update(
        description,
        value,
        (ids, value) => (value ? removeDisabled(ids) : addDisabled(ids)),
        props.onSettingsChanges,
        attributes
      )
    }
  }, [value, attributes])

  const findAddress = (items, address) =>
    items.find((item) => item[outgoingServerEnum.ADDRESS] === address)

  const setData = (newTableData) => {
    setDisplayError(true)
    const newItem = (item, usage, order) => ({
      ...item,
      ...defaultUsage,
      [usage]: true,
      [orderKey(usage)]: order
    })
    const newValue = newTableData[usageItems[0].id].map((x, i) =>
      newItem(x.rowConfig.item, usageItems[0].id, i)
    )
    usageItems.slice(1).forEach(({ id }) => {
      newTableData[id].forEach((x, i) => {
        const found = findAddress(newValue, x[outgoingServerEnum.ADDRESS])
        if (found) {
          found[id] = true
          found[orderKey(id)] = i
        } else {
          newValue.push(newItem(x.rowConfig.item, id, i))
        }
      })
    })
    const settings = [...deviceSettings]
    PoliciesHelper.setData(description, settings, newValue)
    onAttributeChange({ ...props.data, deviceSettings: settings })
  }

  const onChangeTable = (tableId, data, select) => {
    if (!select) {
      setData({ ...tableData, [tableId]: data })
      return // no need to update other tables
    }
    // update all tables
    const newTableData = { ...defaultTableItems(), [tableId]: data }
    usageItems
      .filter(({ id }) => tableId !== id)
      .forEach(({ id }) => {
        newTableData[id] = tableData[id].map((x) => {
          const found = findAddress(data, x[outgoingServerEnum.ADDRESS])
          return found ? { ...found, [TABLE_CONSTANTS.ID]: x.id } : x
        })
      })
    setTableData(newTableData)
  }

  const onChange = (v) => {
    const newTableData = defaultTableItems()
    if (selectedItem) {
      const address = selectedItem[outgoingServerEnum.ADDRESS]
      usageItems.forEach(({ id }) => {
        const arr = tableData[id]
        if (v[id]) {
          const index = arr.findIndex(
            (x) => x[outgoingServerEnum.ADDRESS] === address
          )
          newTableData[id] =
            index >= 0
              ? [
                  ...arr.slice(0, index),
                  tableItem(v, index),
                  ...arr.slice(index + 1)
                ]
              : [...arr, tableItem(v, arr.length)]
        } else {
          newTableData[id] = arr.filter(
            (x) => x[outgoingServerEnum.ADDRESS] !== address
          )
        }
      })
    } else {
      usageItems.forEach(({ id }) => {
        const arr = tableData[id]
        newTableData[id] = v[id] ? [...arr, tableItem(v, arr.length)] : [...arr]
      })
    }
    setData(newTableData)
  }

  const onRemove = () =>
    setData(
      usageItems.reduce((acc, { id }) => {
        acc[id] = tableData[id].filter((x) => !x.rowConfig.selected)
        return acc
      }, {})
    )

  const onAdd = (edit = false) => {
    let item = null
    if (edit) {
      for (const { id } of usageItems) {
        const found = tableData[id].find((x) => x.rowConfig.selected)
        if (found) {
          item = found.rowConfig.item
          break
        }
      }
    }
    setSelectedItem(item)
    setShowModal(true)
  }

  const tableItem = (item, i) => {
    const usage = usageItems
      .filter(({ id }) => item[id])
      .map(({ tag }) => getLocalized(tag))
      .join(', ')
    const address = item[outgoingServerEnum.ADDRESS]
    const selected = usageItems.some(
      ({ id }) => findAddress(tableData[id], address)?.rowConfig.selected
    )
    return {
      [TABLE_CONSTANTS.ID]: i,
      [outgoingServerEnum.ADDRESS]: address,
      [outgoingServerEnum.PORT]: item[outgoingServerEnum.PORT],
      [outgoingServerEnum.USAGE]: usage,
      rowConfig: { selected, item }
    }
  }

  // Migration to new format - can be removed later
  const migrateValue = (val) => {
    const defOrder = defaultOrder()
    return val.reduce((acc, x: Record<string, unknown>) => {
      const thisUsage = usageItems.filter(({ id }) => x[id])
      const found = findAddress(acc, x[outgoingServerEnum.ADDRESS])
      if (found) {
        // add to existing
        thisUsage.forEach(({ id }) => {
          found[id] = true
          found[orderKey(id)] = x[orderKey(id)] ?? defOrder[orderKey(id)]++
        })
      } else {
        // add new
        const order = {}
        thisUsage.forEach(({ id }) => {
          order[orderKey(id)] = x[orderKey(id)] ?? defOrder[orderKey(id)]++
        })
        acc.push({ ...x, ...order })
      }
      return acc
    }, [])
  }

  useEffect(() => {
    const tableItems = defaultTableItems()
    if (value) {
      const migrated = migrateValue(value)
      usageItems.forEach(({ id }) => {
        abcSort(
          migrated.filter((x) => x[id]),
          (x) => x[orderKey(id)]
        ).forEach((x, i) => tableItems[id].push(tableItem(x, i)))
      })
    }
    setTableData(tableItems)
  }, [value])

  useEffect(() => {
    let selected = 0
    const uniqueItems = usageItems.reduce((acc, { id }) => {
      tableData[id].forEach((x) => {
        if (!acc.includes(x[outgoingServerEnum.ADDRESS])) {
          acc.push(x[outgoingServerEnum.ADDRESS])
          if (x.rowConfig.selected) {
            selected++
          }
        }
      })
      return acc
    }, [])
    setUniqueCount(uniqueItems.length)
    setSelectedCount(selected)
  }, [tableData])

  const tableHeader = (text) =>
    !onAttributeChange ? (
      <PreviewLabel>{text}</PreviewLabel>
    ) : (
      <TextBlack className={'marginBottom12'} disabled={!enabled}>
        {text}
      </TextBlack>
    )

  return (
    <>
      <FlexColumn>
        {tableHeader(getLocalized('server-list'))}
        {onAttributeChange && (
          <FlexRow className={'alignCenter'}>
            <ButtonGroup>
              <Button
                id={'addOutgoingServer'}
                onClick={() => onAdd()}
                disabled={!enabled || uniqueCount >= maxServers}
                appearance={'secondary'}
              >
                {getLocalized('common.add')}
              </Button>
              <Button
                id={'editOutgoingServer'}
                onClick={() => onAdd(true)}
                disabled={!enabled || selectedCount !== 1}
                appearance={'secondary'}
              >
                {getLocalized('common.edit')}
              </Button>
              <Button
                id={'removeOutgoingServer'}
                onClick={onRemove}
                disabled={!enabled || !selectedCount}
                appearance={'secondary'}
              >
                {getLocalized('common.remove')}
              </Button>
            </ButtonGroup>
            <TextBlack className={'marginLeft12'} disabled={!enabled}>
              {getLocalized('n-servers-added', {
                count: uniqueCount,
                max: maxServers
              })}
            </TextBlack>
          </FlexRow>
        )}
        {usageItems
          .filter(({ id }) => tableData[id].length)
          .map(({ id }) => (
            <OutgoingServersTable
              key={id}
              usage={id}
              enabled={enabled}
              getLocalized={getLocalized}
              tableData={tableData[id]}
              setTableData={
                onAttributeChange
                  ? (data, select) => onChangeTable(id, data, select)
                  : undefined
              }
            />
          ))}
        {error && (
          <WarningMessage
            id={attribute + '.error'}
            type={MessageTypesEnum.ERROR}
            message={getLocalized('common.errors.not-selected')}
          />
        )}
        {compliance && (
          <PreviewItem compliance={compliance} className={'marginTop4'} />
        )}
      </FlexColumn>
      {showModal && (
        <OutgoingServerModal
          constraints={description.constraints}
          value={selectedItem || defaultData}
          edit={!!selectedItem}
          onChange={(v) => onChange(v)}
          onClose={() => setShowModal(false)}
          getLocalized={getLocalized}
          tableData={tableData}
        />
      )}
    </>
  )
}

export default memo(OutgoingServersControl)
