import { Button } from '@veneer/core'
import React, { useEffect, useState } from 'react'
import { FlexRow } from 'styles/styles'
import DeviceCompliancePreview from 'components/policies/compliance'
import NoItemsCard from 'common/controls/noItems'
import './index.scss'
import 'styles/global.scss'
import AccordionWrapper from './AccordionWrapper'

const CompliancePolicies = (props) => {
  const {
    allPolicies,
    getLocalized,
    policyName,
    policyId,
    checkIcon,
    setShowModal
  } = props
  const [items, setItems] = useState([])

  useEffect(() => {
    setItems(
      allPolicies.map((policy, index) => {
        const noDeviceSpecific = !index && !policy.attributes.length
        return {
          id: policyId(policy.policyId),
          content: noDeviceSpecific ? (
            <NoItemsCard
              title={getLocalized('policy.details.device.notFound')}
              description={getLocalized('policy.details.device.tryEditing')}
              noCard={true}
            />
          ) : (
            <DeviceCompliancePreview {...policy} />
          ),
          expanded: false,
          header: {
            centralArea: (
              <div className={'centralAreaAccordion wordBreakWord'}>
                {getLocalized('priorityAndName', {
                  priority: index + 1,
                  name: policyName(policy.name)
                })}
              </div>
            ),
            endArea: (
              <FlexRow className={'alignCenter'}>
                {!noDeviceSpecific && checkIcon(policy.status)}
                {!index && (
                  <Button
                    small
                    id={'device-policy-preview-accordion-button'}
                    appearance={'ghost'}
                    onClick={() => setShowModal(true)}
                  >
                    {getLocalized('common.edit')}
                  </Button>
                )}
              </FlexRow>
            )
          }
        }
      })
    )
  }, [allPolicies, checkIcon, policyName, getLocalized])

  const handleExpand = (event, index) => {
    const updatedData = [...items]
    updatedData[index].expanded = true
    setItems(updatedData)
  }

  const handleCollapse = (event, index) => {
    const updatedData = [...items]
    updatedData[index].expanded = false
    setItems(updatedData)
  }

  return (
    <AccordionWrapper
      gutter={16}
      items={items}
      behavior={'multiExpand'}
      id={'policy-accordion'}
      border={'dropShadow'}
      onExpand={handleExpand}
      onCollapse={handleCollapse}
    />
  )
}
export default CompliancePolicies
