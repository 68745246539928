import { PolicyAttributeEnum } from 'context/policies/PoliciesCategories'
import { Item } from '../PoliciesConfiguration'

const signOutItem = (x) => ({
  value: `${PolicyAttributeEnum.Device_Access_Ctrl}.sign-out-after.` + x,
  label: 'sign-out-after-' + x
})

const signInItem = (x) => ({
  value: `${PolicyAttributeEnum.Device_Access_Ctrl}.sign-in-method.` + x,
  label: 'sign-in-method-' + x
})

export default class DeviceAccessItems {
  static signOutAfterOptions: Item[] = [
    signOutItem('immediate'),
    signOutItem('delayed')
  ]

  static signInMethods: Item[] = [
    signInItem('default'), // default is not a real sign-in method
    signInItem('local'),
    signInItem('windows'),
    signInItem('ldap'),
    signInItem('smartcard'), // for Control Panel only
    signInItem('siprnet-smartcard') // for Control Panel only
  ]
  static signInEwsMethods: Item[] = DeviceAccessItems.signInMethods.slice(0, -2)
}
