import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { Button } from '@veneer/core'
import policiesContext from 'context/policies/policiesContext'
import PolicySettings from 'components/policies/settings'
import { setPolicy } from 'common/setPolicy'
import './index.scss'
import 'styles/global.scss'
import { equalPolicies } from 'common/policyAttributeComparator'
import configContext from 'context/config/configContext'
import PoliciesCategories from 'context/policies/PoliciesCategories'
import Footer from 'components/policies/footer'
import Save from 'components/policies/modal/save'
import ConfirmSave from 'components/policies/modal/confirmSave'
import WarningSave from 'components/policies/modal/warningSave'
import PolicyDetailsEditForm from 'components/policies/policyDetailsEditForm/policyDetailsEditForm'
import PolicyCategories from 'components/policies/categories'
import {
  FlexColumnTree,
  FlexColumnRight,
  FlexColumn,
  FlexRow
} from 'styles/styles'
import { useParams, useHistory, Redirect } from 'react-router-dom'
import AddPolicyModal from './AddPolicyModal/AddPolicyModal'
import {
  RELOAD_EVENT,
  WRITE_SCOPES,
  READ_SCOPES,
  ToastIdEnum
} from '../constants'

const PolicyDetails = (props) => {
  const {
    getPolicy,
    selectedPolicy,
    setSelectedPolicy,
    clonedPolicy,
    removeSelectedPolicyAttribute,
    changeSelectedPolicyAttribute,
    savePolicy
  } = useContext(policiesContext)

  const { policyId } = useParams()
  const { baseurl, navigation, displayToast, accessControl, events } = props
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const history = navigation || useHistory()

  const { t, isWex, getCategoryName } = useContext(configContext)

  const [showSave, setShowSave] = useState(false)
  const [showAddPolicyModal, setShowAddPolicyModal] = useState(false)
  const [showConfirmSave, setShowConfirmSave] = useState(false)
  const [showSaveFooter, setShowSaveFooter] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState([])
  const [policyWarning, setPolicyWarning] = useState(false)
  const [policyError, setPolicyError] = useState(false)
  const [displayAllPolicyErrors, setDisplayAllPolicyErrors] = useState(false)

  const [allowWrite, setAllowWrite] = useState(true)
  const [allowRead, setAllowRead] = useState(isWex) // TO DO: isWex -> false
  const key = (subkey: string): string => {
    return t(`policy.details.${subkey}`)
  }

  const showFooter = useCallback(() => {
    const equal =
      selectedPolicy && clonedPolicy
        ? equalPolicies(selectedPolicy, clonedPolicy)
        : true
    setShowSaveFooter(!equal)
  }, [selectedPolicy, clonedPolicy])

  const checkScopes = async () => {
    setAllowWrite(await accessControl.checkScopes(WRITE_SCOPES))
    setAllowRead(await accessControl.checkScopes(READ_SCOPES))
  }

  useEffect(() => {
    // TO DO: remove !isWex
    if (accessControl && !isWex) {
      checkScopes()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessControl])

  const reload = useCallback(() => {
    if (policyId) {
      getPolicy(
        policyId,
        () => history.push(baseurl),
        (error) => displayToast(ToastIdEnum.GET_POLICY, error, 'negative')
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policyId, history, baseurl])

  useEffect(() => reload(), [policyId, reload])

  useEffect(() => {
    if (events) {
      events.addEventListener(RELOAD_EVENT, reload)
      return () => events.removeEventListener(RELOAD_EVENT, reload)
    }
  }, [events, reload])

  const selectedAttributes = useMemo(() => {
    return selectedPolicy
      ? PoliciesCategories.filterPolicyAttributes(
          selectedCategory,
          selectedPolicy.attributes
        )
      : null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPolicy, selectedPolicy?.attributes, selectedCategory])

  useEffect(() => {
    showFooter()
  }, [selectedPolicy, clonedPolicy, selectedPolicy?.attributes, showFooter])

  const checkEditPermission = useMemo(() => {
    return <Redirect to={`/${policyId}`} />
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowWrite, policyId])

  const onPolicyAttributeChange = (attribute) => {
    changeSelectedPolicyAttribute(attribute)
    showFooter()
  }

  const doCancel = () => {
    setShowSaveFooter(false)
    setSelectedPolicy(clonedPolicy)
  }

  const doSave = () => {
    setDisplayAllPolicyErrors(true)
    if (
      selectedPolicy?.name &&
      selectedPolicy?.attributes?.length &&
      !policyError
    ) {
      policyWarning ? setShowConfirmSave(true) : setShowSave(true)
    }
  }
  const closeSave = () => setShowSave(false)
  const closeConfirm = () => setShowConfirmSave(false)

  const doConfirm = () => {
    closeConfirm()
    setShowSave(true)
  }

  const doSavePolicy = () => {
    closeSave()
    savePolicy(setPolicy(selectedPolicy), () => {
      displayToast(ToastIdEnum.SAVE_POLICY, key('footer.success'))
      history.goBack()
    })
  }

  const addButton = () => {
    return (
      <div className={'marginLeft12'}>
        {allowRead && (
          <Button
            onClick={() => setShowAddPolicyModal(true)}
            appearance={'secondary'}
            id={'addPolicyButton'}
          >
            {t('common.add')}
          </Button>
        )}
      </div>
    )
  }

  return (
    <div id={'policyDetails'}>
      {allowWrite ? (
        <FlexColumn>
          <div>
            <PolicyDetailsEditForm
              selectedPolicy={selectedPolicy}
              onNameChange={(val) => {
                selectedPolicy.name = val.trim()
                showFooter()
              }}
              onDescriptionChange={(val) => {
                selectedPolicy.description = val.trim()
                showFooter()
              }}
            />
          </div>
          <FlexRow className={'marginTop20' + (isWex ? ' marginBottom60' : '')}>
            <FlexColumnTree>
              <PolicyCategories
                attributes={selectedPolicy?.attributes || []}
                onChange={setSelectedCategory}
              />
            </FlexColumnTree>
            <FlexColumnRight>
              <PolicySettings
                attributes={selectedAttributes}
                onAttributeChange={onPolicyAttributeChange}
                onWarning={setPolicyWarning}
                onError={setPolicyError}
                onRemove={removeSelectedPolicyAttribute}
                searchCategory={getCategoryName(selectedCategory)}
                showErrors={displayAllPolicyErrors}
                actions={addButton()}
              />
            </FlexColumnRight>
          </FlexRow>
        </FlexColumn>
      ) : (
        checkEditPermission
      )}
      {showSaveFooter && (
        <Footer
          onSave={doSave}
          onCancel={doCancel}
          error={!selectedPolicy?.attributes.length}
          isWex={isWex}
        />
      )}
      <Save
        show={showSave}
        policyName={selectedPolicy?.name}
        onSave={doSavePolicy}
        onCancel={closeSave}
      />
      <ConfirmSave
        show={showConfirmSave}
        onConfirm={doConfirm}
        onCancel={closeConfirm}
      />
      <WarningSave history={history} />
      {showAddPolicyModal && (
        <AddPolicyModal
          setShowAddPolicyModal={setShowAddPolicyModal}
          selectedAttrs={(selectedSettings) =>
            (selectedPolicy.attributes = selectedSettings)
          }
          selectedPolicyAttributes={selectedPolicy.attributes}
        />
      )}
    </div>
  )
}

export default PolicyDetails
