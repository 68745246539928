import React, { useState } from 'react'
import { Button, ButtonGroup, Modal } from '@veneer/core'
import LabelTextBox from 'common/controls/labelTextBox'
import { DeviceAccessRolesModalEnum } from './DeviceAccessEnums'
import 'components/policies/modal/index.scss'
import 'styles/global.scss'

const hint = 'roles-name-new_hint'

const DeviceAccessRolesModal = (props) => {
  const { show, value, values, onClose, onChange, getLocalized } = props
  const [inputValue, setInputValue] = useState(value)
  const [error, setError] = useState(false)

  const checkDuplicates = (value): boolean => {
    const err = values.includes(value.trim())
    setError(err)
    return err
  }

  const handleTextChange = (value) => {
    checkDuplicates(value)
    setInputValue(value)
  }

  const handleSelect = () => {
    if (!checkDuplicates(inputValue)) {
      onChange(inputValue.trim())
      onClose()
    }
  }

  return (
    <Modal
      onClose={onClose}
      closeOnBlur={false}
      show={true}
      title={getLocalized('roles-' + show)}
      className={'small-policy-modal'}
      data-testid={'id-device-access-role-modal'}
      footer={
        <ButtonGroup>
          <Button onClick={handleSelect} disabled={!inputValue}>
            {getLocalized(
              show === DeviceAccessRolesModalEnum.ADD
                ? 'common.add'
                : 'common.save'
            )}
          </Button>
          <Button appearance={'secondary'} onClick={onClose}>
            {getLocalized('common.cancel')}
          </Button>
        </ButtonGroup>
      }
    >
      <p className={'paddingBottom16'}>
        {getLocalized(`roles-${show}-description`, { name: value })}
      </p>

      <LabelTextBox
        label={getLocalized('roles-name-new')}
        id={'id-device-access-role-name'}
        placeholder={getLocalized(hint)}
        value={inputValue || ''}
        onChange={(value) => handleTextChange(value)}
        error={error}
        helperText={getLocalized(error ? 'roles-duplicate-error' : hint)}
        className={'maxTextWidth'}
      />
    </Modal>
  )
}
export default DeviceAccessRolesModal
